import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../../queries/authQueries'
import { Link } from 'react-router-dom'
import LoaderCommon from '../../Common/LoaderCommon'
import moment from 'moment';
import { Empty } from 'antd';
import {getCurrentFinancialYear, getNextFinancialYear, getPreviousFinancialYear} from '../../Common/financialYear';
import { IconTrophy } from '@tabler/icons-react';
import { Radio } from "@nextui-org/react";
import { useAdvancedSearch } from '../../../store/advancedSearchStore';
import SelectSearch from "../../Common/SelectSearch";
import { userStore } from '../../../store/userStore';
import InputSearch from "../../Common/InputSearch";

const TopPerformerScreen = () => {

    const today = new Date()
    const [result, setResult] = useState([])
    const [dateRange, setDateRange] = useState("CURRENT")
    const [fromDate, setFromDate] = useState(today)
    const [toDate, setToDate]= useState(today)
    const [selectedStatus, setSelectedStatus] = useState("ALL");
    const [branch, setbranch] = useState("ALL")

    const office = useAdvancedSearch((state) => state.office);
    const setOffice = useAdvancedSearch((state) => state.setOffice);
    const store = userStore();
    const [masterData, setMasterData] = useState({})
    const counselor = useAdvancedSearch((state) => state.counselor);
    const setCounselor = useAdvancedSearch((state) => state.setCounselor);

    const currentFinancialYear = getCurrentFinancialYear()
    const nextFinancialYear = getNextFinancialYear()
    const previousFinancialYear = getPreviousFinancialYear()

    const topPerformListing = authQueries.useTopcounselorReportMutation(
        (res)=>{
            setResult(res)
            console.log("res",res?.data[0])
        }
    )

    const handleStatusChange = (value) => {
        setSelectedStatus(value);
        console.log("value", value)
      };

    useEffect(()=>{
        topPerformListing.mutateAsync({from: fromDate, to: toDate, type: dateRange, selectedStatus, office:office?office:0, counselor:counselor?counselor:0 })
        advancedMasterList.mutateAsync({visa: ''})
    },[])

    const onSearch =()=>{
        topPerformListing.mutateAsync({from: fromDate, to: toDate, type: dateRange, selectedStatus, office:office?office:0, counselor:counselor?counselor:0 })
    }

    const selectDate=(e)=>{
        setDateRange(e.target.value)
    }

    const advancedMasterList = authQueries.useAdvancedSearchMutation(
        (response) => {
            setMasterData(response?.data)
        }
    );


    let totalVisa = 0;
    let totalTarget = 0;
    let totalVisaApproved = 0;
    const countryTotals = {};


    result?.countries?.forEach(country => {
        countryTotals[country.id] = 0;
    });


    result?.data?.[0]?.forEach(item => {
        totalVisa += item?.app_count || 0;
        totalTarget += item?.target_count || 0;
        totalVisaApproved += item?.visa_approved_count || 0;
        result?.countries?.forEach(country => {
            countryTotals[country.id] +=  parseInt(item[country.id]) || 0;
        });
    });


    const bmOffice = store?.user?.office?.map(item=>{
        return item?.office
    })


    const findUser =(role, isHiddenNotAssigned)=>{
        let filterData = []
        if(!isHiddenNotAssigned){
            filterData.push({id:"NOTASSIGNED", name:"Not Assigned"})
        }
        const data = masterData?.user?.filter(user => user.roles.includes(role))
        data?.map((item) => {
            filterData.push({
                id: item.id,
                name: item.name
            })
        })
        return filterData
    }


    const inputSearchArry = [

        {
            label: "Branch Office",
            size: 2,            
            state: office,
            setState: setOffice,
            input: SelectSearch,
            value: (store?.user?.role === "BMANAGER" || store?.user?.role === "SENIORPROGRAMMANAGER") ? bmOffice : masterData?.office,
            hidden: store?.user?.role === "RM" ? true : false,
            name: 'name',
            simple: true,
        },
        {
            label: "Counselor",
            size: 2,            
            state: counselor,
            setState: setCounselor,
            input: SelectSearch,
            value: findUser(3),
            name: 'name',
            simple: true,
        },

    ]





  return (
    <div>

        <div className="breadcrumb-and-otherbuttons">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Top Performing Counselors</li>
                </ol>
            </nav>
        </div>
            
        <div className="add_clients">
            <div className="flex items-center gap-[4px] mb-4">
                <i className="followup-icon">
                    <IconTrophy size="25" className='text-[#7D6FAB]' />
                </i>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                        <span className="text-[16px] font-medium">Top Performing Counselors</span>	
                    </div>
                </div>
            </div>
            <div id="accordion" className="add_clients_accordion_form">
                <div className="card" id="appointments_wrpr">
                    <div className="card-header">
                        Top Performing Counselors
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                        <div className="card-body">

                            <div className="row ">
                                <div className="col-md-12">
                                    <div className="table-list-search-wrpr table-report-ceo overflow-auto">
                                        <div className="row">
                                            <div className="col-md-6">
                                            </div>
                                            <div className="col-md-6 text-right">
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-12 gap-4 max-[1446px]:grid-cols-10 max-[1094px]:grid-cols-8 max-[949px]:grid-cols-6 max-[640px]:grid-cols-4 max-[483px]:grid-cols-2 !w-full mb-4'>
                                        
                                        <div className='col-span-2'>
                                            <Radio.Group  value={selectedStatus} onChange={handleStatusChange} label="User Status" defaultValue="primary" orientation="horizontal">
                                            <Radio value="ALL" color="primary" labelColor="primary">
                                                ALL
                                            </Radio>
                                            <Radio value="ACTIVE" color="success" labelColor="success">
                                                ACTIVE
                                            </Radio>
                                            </Radio.Group>
                                        </div>
                                        <div className='col-span-3'>
                                            <label>Select Date</label>
                                            <select value={dateRange} className='form-control form-control-sm !w-full !min-h-[38px]' onChange={selectDate}>
                                                <option value="PREVIOUS">Previous Financial Year ({previousFinancialYear})</option>
                                                <option value="CURRENT">Current Financial Year ({currentFinancialYear})</option>
                                                <option value="FUTURE">Next Financial Year ({nextFinancialYear})</option>
                                                <option value="CUSTOM">Custom Date</option>
                                            </select>
                                        </div>
                                        {dateRange === "CUSTOM" &&
                                            <Fragment>
                                                <div className='col-span-2'>
                                                    <label>From Date</label>
                                                    <input 
                                                    max={moment(toDate).format("YYYY-MM-DD")} 
                                                    value={moment(fromDate).format('YYYY-MM-DD')} 
                                                    type='date' onChange={(e)=> {setFromDate(e.target.value)}} 
                                                    className='form-control form-control-sm !w-full !min-h-[38px]' 
                                                    />
                                                </div>
                                                <div className='col-span-2'>
                                                    <label>To Date</label>
                                                    <input 
                                                    min={moment(fromDate).format("YYYY-MM-DD")} 
                                                    value={moment(toDate).format('YYYY-MM-DD')} 
                                                    type='date' onChange={(e)=> {setToDate(e.target.value)}} 
                                                    className='form-control form-control-sm !w-full !min-h-[38px]' 
                                                    />
                                                </div>
                                            </Fragment>
                                        }

                                        {                                       
                                            inputSearchArry?.map((item, index)=> {
                                                if(item?.blank && !item?.hidden){
                                                    return  (
                                                                <div className={`col-span-${item?.size} flex flex-col gap-2`}/>
                                                            )
                                                        }
                                                if(item?.hidden){
                                                        return
                                                        }
                                                return(
                                                    <Fragment key={index}>
                                                    {
                                                        (item?.simple ) &&
                                                        <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                                                        <item.input item={item} />
                                                        </div>
                                                    }
                                                    </Fragment>
                                            )})
                                        }
                                        <div className='col-span-1 flex flex-col'>
                                            <label>&nbsp;</label>
                                            <button onClick={onSearch} className='mt-[3px] rounded-md text-[12px] !w-full !min-h-[38px] bg-[#323a51] border-none text-white'>
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                    {topPerformListing?.isLoading ?
                                        <LoaderCommon />:
                                        <Fragment>
                                            {
                                                result?.data?.length > 0 ?
                                                <table border={1} className="table report collageprogram">
                                                    <thead>
                                                        <tr>
                                                            <th>Sl No</th>
                                                            <th className='min-w-[200px]'>Branch Name</th>
                                                            <th className='min-w-[300px]'>Counselor Name</th>
                                                            <th className='min-w-[100px]'>Target</th>
                                                            <th>Total Expected Visa</th>
                                                            <th className='min-w-[100px]'>Visa Approved</th>
                                                            
                                                            {
                                                                result?.countries?.map((item, index)=>(
                                                                    <th className='text-center'>{item.name}</th>
                                                                ))
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody className="ui-sortable">
                                                        {
                                                            result?.data[0]?.map((item, index)=>(
                                                                <tr style={item?.office_id===145 || item?.office_id===144 || item?.office_id===113 ? {backgroundColor:"#a7f3d0"}:{}} key={index}>
                                                                    <td className='!font-bold !text-[12px]'>{index+1}</td>
                                                                    <td className='!font-bold !text-[12px]'>{item?.office }</td>
                                                                    <td className='!font-bold !text-[12px]'>{item?.firstname+' '+item?.lastname}</td>
                                                                    <td className='!font-bold !text-[12px]'>{item?.target_count }</td>
                                                                    <td className='!font-bold !text-[12px] text-center'>
                                                                       {item?.app_count}
                                                                    </td>
                                                                    <td className='!font-bold !text-[12px]'>{item?.visa_approved_count }</td>
                                                                    
                                                                    {
                                                                        result?.countries?.map((country, indexes)=>(
                                                                            <td className='!font-bold !text-[12px] text-center'>
                                                                                { item[country.id] }
                                                                            </td>
                                                                        ))
                                                                    }
                                                                </tr>
                                                            ))
                                                        }
                                                        <tr>
                                                            <td  colSpan={3} className='text-center text-[14px] font-bold'>Total</td>
                                                            <td className='!font-bold !text-[12px] text-center'>
                                                                {totalTarget}
                                                            </td>
                                                            <td className='!font-bold !text-[12px] text-center'>
                                                                {totalVisa}
                                                            </td>
                                                            <td className='!font-bold !text-[12px] text-center'>
                                                                {totalVisaApproved}
                                                            </td>
                                                           
                                                            {
                                                                result?.countries?.map((item, index)=>(
                                                                    <td key={index} className='!font-bold !text-[12px] text-center'>
                                                                        {countryTotals[item.id]}
                                                                    </td>
                                                                ))
                                                            }
                                                        </tr>
                                                    </tbody>

                                                </table>:
                                                <div className='mt-28 mb-28'>
                                                    <Empty description={<div className="text-sm">No data found!</div>} />
                                                </div>
                                            }
                                        </Fragment>
                                    }
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TopPerformerScreen


