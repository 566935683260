
import { GanttChartSquare } from "lucide-react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import reportQueries from "../../../../queries/reportQueries";
import LoaderCommon from "../../../Common/LoaderCommon";
import { getCurrentFinancialYear, getNextFinancialYear, getPreviousFinancialYear } from '../../../Common/financialYear';

const BranchWiseCountryFeePaidReport =()=>{


    const currentFinancialYear = getCurrentFinancialYear();
    const nextFinancialYear = getNextFinancialYear();
    const previousFinancialYear = getPreviousFinancialYear();

    const [result, setResult] = useState([]);
    const [financialYear, setFinancialYear] = useState('CURRENT')

    const counselorScore = reportQueries.useBranchWiseCountryFeepaidReport(
        (res)=>{
            setResult(res)
        }
    )

    useEffect(()=>{
        counselorScore.mutateAsync({financialYear})
    },[financialYear])
    
    return(
        <div>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Fee Paid Branch Wise Report</li>
                    </ol>
                </nav>
            </div>	
            <div className="add_clients">
                <div className="flex items-center gap-[4px] mb-4">
                    <i className="followup-icon">
                        <GanttChartSquare size="25" className='text-[#7D6FAB]' />
                    </i>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                            <span className="text-[16px] font-medium">Fee Paid Branch Wise Report</span>	
                        </div>
                    </div>
                </div>

                <div id="accordion" className="add_clients_accordion_form">
                    <div className="card" id="appointments_wrpr">
                        <div className="card-header text-[14px]">
                            Fee Paid Branch Wise Report
                        </div>
                        {
                            counselorScore.isLoading ? <LoaderCommon  /> :
                            <div id="appointment_any" className="collapse show" data-parent="#accordion">
                                <div className="card-body">
                                    <div className='grid grid-cols-12 gap-4 max-[1446px]:grid-cols-10 max-[1094px]:grid-cols-8 max-[949px]:grid-cols-6 max-[640px]:grid-cols-4 max-[483px]:grid-cols-2 !w-full mb-4'>
                                        <div className='col-span-3'>
                                            <label>Select Date</label>
                                            <select value={financialYear} className='form-control form-control-sm !w-full !min-h-[38px]' onChange={(e)=>setFinancialYear(e.target.value)}>
                                                <option value="PREVIOUS">Previous Financial Year ({previousFinancialYear})</option>
                                                <option value="CURRENT">Current Financial Year ({currentFinancialYear})</option>
                                                <option value="FUTURE">Next Financial Year ({nextFinancialYear})</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                                
                                            <div className="table-list-search-wrpr table-report-ceo overflow-auto">
                                                <div className="overflow-x-auto max-h-[600px]">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <table border={1}  className="table report associate-report collageprogram overflow-auto ">
                                                                <thead style={{zIndex:100}} className="sticky top-0">
                                                                    <tr>
                                                                        <th className="min-w-[50px] text-center fixed-column data py-2">#</th>
                                                                        <th className="min-w-[250px] !text-[13px] fixed-column text-center data" style={{backgroundColor:"var(--primary-color)"}}>Branch Name</th>
                                                                        <th className="min-w-[100px] !text-[13px] text-center py-2">Total Fee Paid</th>
                                                                        {result?.uniqueCountries?.map((country, index) => {                                                                            
                                                                            return(
                                                                                <th className="min-w-[100px] !text-[13px] text-center py-2" key={index}>{country?.country_name}</th>
                                                                            )
                                                                        })}
                                                                    </tr>
                                                                </thead>
                                                                {result?.data?.map((item, index) => (
                                                                    <tbody key={index} className={`ui-sortable ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}>
                                                                        <tr className="ui-sortable-handle">
                                                                            <td className="!text-[13px] text-center fixed-column data">{index + 1}</td>
                                                                            <td className="!text-[13px] text-center fixed-column data bg-white">{item?.name}</td>
                                                                            <td className="!text-[13px] text-center data">{item?.total_count}</td>
                                                                            {result?.uniqueCountries?.map((country, index) => {
                                                                                const countryData = item?.country_counts?.find((i) => i?.country_id === country?.country_id)
                                                                                return(
                                                                                    <td className="!text-[13px] text-center data" key={index}>
                                                                                        {countryData ? countryData?.count : 0}
                                                                                    </td>
                                                                                )
                                                                            })}
                                                                        </tr>
                                                                    </tbody>
                                                                ))}
                                                            </table>   
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BranchWiseCountryFeePaidReport