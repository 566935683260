import { Modal, Textarea } from '@nextui-org/react'
import { message } from 'antd'
import React, { Fragment, useState } from 'react'
import authQueries from '../../queries/authQueries'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { generateValidationSchema } from './validationSchema'
import { useApi } from './ApiProvider'
import toast from 'react-hot-toast'

const HoldProgram = ({item}) => {

    const inputFields = [
        {
            name: "comment",
            label: "Comment",
            required: true
        }
    ]

    const [open, setOpen] = useState(false)

    const { setApiDataAndNotify } = useApi()

    const { handleSubmit, register, formState: {errors}, setValue } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    })

    const handleOpen =()=> {
        setOpen(true)
    }

    const handleClose =()=> {
        setOpen(false)
    }

    const onSubmit =(data)=> {
        const dataValues = {
            id: item?.id,
            comment: data?.comment,
            status: "HOLD"
        }
        programConfirm.mutateAsync(dataValues)
    }

    const programConfirm = authQueries.useIntProgramConfirm(
        (response) => {
            setApiDataAndNotify(response)
            setValue('comment', '');
            handleClose()
            toast.success('Program rejected successfully')
        }   
    );

  return (
    <Fragment>
        <button 
        disabled={(item?.status  === "CONFIRMED" || item?.status === "HOLD") ? true:false}
        onClick={handleOpen} 
        className={`btn-customized bg-purple-600 ${item?.status === "PENDING" && "hover:scale-110"}`}
        style={{opacity : (item?.status === "CONFIRMED" || item?.status === "HOLD") && "0.7"}}
        >
        Hold
        </button>
        <Modal
            open={open}
            closeButton
            onClose={handleClose}
            className="followup-modal"
            centered
            blur
            animationDuration={1000}
            overlayId="modal-overlay"
            width='700px'
        >
            <Modal.Header>
                <h5 className="modal-title">Program Hold Comments</h5>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='w-full mb-2'>
                        <textarea
                        {...register('comment')}
                        name='comment'
                        className="bg-white border border-solid border-gray-300 rounded-md w-full h-10 p-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 min-h-[100px]" 
                        rows={6} 
                        placeholder='Enter comment' 
                        />
                        {errors?.comment && <div className='text-red-500 text-[14px]'> {errors?.comment?.message}</div>}
                    </div>
                    <div className='w-full flex justify-end'>
                        <div className='w-[140px]'>
                            <button 
                            type='submit' 
                            className="btn-customized-large"
                            >Hold Program</button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    </Fragment>
  )
}

export default HoldProgram
