import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Eye, EyeOff } from "lucide-react";
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import authQueries from '../../queries/authQueries';
import { userStore } from '../../store/userStore';
import { useRmStore } from '../../store/rmRoleStore';
import { useDelegateStore } from '../../store/delegateStore';
import RouteConstants from '../../Constants/RouteConstans';

  const validationSchema = yup.object({
    username: yup
      .string('⚠ Enter your Name')
      .required('⚠ Name is required')
      .max(20, '⚠ Name should be of Maximum of 20 characters length'),
    password: yup
      .string('⚠ Enter your password')
      .required('⚠ Password is required')
      .min(4, '⚠ At least 4 characters Long'),
  });

const LoginPage = () => {

  const store = userStore();
  const navigate = useNavigate();
  const [isError, setIsError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const setVisible = useDelegateStore((state) => state.setVisible);
  const setOffice = useRmStore((state) => state.setOffice);

  useEffect(() => {
    if (store?.user?.accessToken) {
      navigate("/dashboard");
    }
  }, []);

  const login = authQueries.useLoginMutation(
    async(response) => {
      if(response?.message === "Username or password is incorrect."){
        return setIsError(response?.message);
      }

      if(response?.message === 'Login successfull') {
        if(response?.data?.role){
          if(response?.data?.office?.length > 0){
            setOffice(response?.data?.office[0]?.oid)
          }
        }
        if (response.data?.id) {
          await store.setUser({ ...response?.data });
          navigate(RouteConstants.DASHBOARD)
          if(response?.data?.role !== "MARKETINGASSOCIATE"){
            setVisible(true)
          }
        }
      } 
    }
  );

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (data) => {
    store.setActiveTab('/dashboard')
    const datavalues = {
      username: data.username,
      password: data.password,
    };
    login.mutateAsync(datavalues);
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        duration: 0.5, 
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 0.4 } }
  };

  return (
    <div
    className="min-h-screen bg-gradient-to-br flex items-center justify-center w-full py-12 px-4 sm:px-6 lg:px-8 overflow-auto custom-color"
    >
      <div className="max-w-6xl w-full flex flex-col md:flex-row bg-white rounded-2xl shadow-2xl overflow-hidden">
        <motion.div 
        className="w-full md:w-5/12 p-8 md:p-12"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        >
          <motion.div variants={itemVariants} className="flex justify-center md:justify-start mb-8">
            <img src={require('../../assets/images/logo.png')} alt="GeeBee Logo" className="h-12" />
          </motion.div>
          
          <motion.div variants={itemVariants} className="mb-8">
            <h2 className="text-3xl font-bold text-gray-800">Login to&nbsp;
            <span
            className="text-3xl font-bold text-gray-800" >
              GeeBee
            </span>
            </h2>
            <p className="text-gray-600 mt-2">Please login with your username</p>
          </motion.div>
          
          <form onSubmit={handleSubmit(onSubmit)}>
            <motion.div variants={itemVariants} className="mb-6">
              <label htmlFor="username" className="block text-sm font-medium text-gray-700 mb-1">Username</label>
              <input
                id="username"
                type="text"
                {...register("username")}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                placeholder="Enter your username"
              />
              {errors.username && <p className="mt-1 text-red-500 text-sm">{errors.username.message}</p>}
            </motion.div>
            
            <motion.div variants={itemVariants} className="mb-6">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">Password</label>
              <div className="relative">
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  {...register("password")}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
                  placeholder="Enter your password"
                />
                <button 
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 transition duration-200"
                >
                  {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>
              {errors.password && <p className="mt-1 text-red-500 text-sm">{errors.password.message}</p>}
            </motion.div>
            
            {isError && (
              <motion.div 
                variants={itemVariants}
                className="mb-4 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg"
              >
                {isError}
              </motion.div>
            )}
            
            <motion.div variants={itemVariants} className="mt-8">
              <button
                type="submit"
                disabled={login.isLoading}
                className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium 
                text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 
                focus:ring-blue-500 transition duration-200 custom-color"
              >
                {login.isLoading ? (
                  <div className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing...
                  </div>
                ) : (
                  "Login"
                )}
              </button>
            </motion.div>
          </form>
        </motion.div>
        
        {/* Right Column - Background Image */}
        <motion.div 
          className="hidden md:block md:w-7/12 bg-cover bg-center"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.7 }}
          style={{ 
            backgroundImage: "url('" + require('../../assets/images/login-bg.jpg') + "')" 
          }}
        >
        </motion.div>
      </div>
    </div>
  );
}

export default LoginPage;