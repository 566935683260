/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../queries/authQueries';
import { useApplicationFee, useLoaderStore, usePaginationStore, userStore } from '../../store/userStore';
import moment from 'moment';
import { decode } from 'html-entities';
import LoaderCommon from '../Common/LoaderCommon';
import FollowUp from '../Common/FollowUp';
import { Empty } from 'antd';
import PaginationComponent from '../Common/PaginationComponent';
import LeadStatus from '../Common/LeadStatus';
import ProspectStatusList from '../Common/ProspectStatusList';
import { useApi } from '../Common/ApiProvider';
import ApplicantViewButton from '../Common/ApplicantViewButton';
import ApplicationStatusModal from '../Common/ApplicationStatusModal';
import AdvancedSearch from '../Common/AdvancedSearch';
import { useAdvancedSearch } from '../../store/advancedSearchStore';
import { IconClearAll } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import RouteConstants from '../../Constants/RouteConstans';
import LoginDetailsModal from '../Common/LoginDetailsModal';
import PaymentHistoryModal from '../Common/PaymentHistoryModal';
import PaymentCreateModal from '../Common/PaymentCreateModal';
import DocumentUploadModal from '../Common/DocumentUploadModal';
import ApplicationStatusCreateModal from '../Common/ApplicationStatusCreateModal';

const FeeCoordinatorMyClients = () => {

    const { apiData } = useApi();
    const [result, setResult] = useState([]);
    const [active, setActive] = useState({ index: null, status: false });

    
    
    const navigate = useNavigate();

    const data = useAdvancedSearch((state) => state);

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);
    const loader = useLoaderStore((state) => state.loader);
    const setLoader = useLoaderStore((state) => state.setLoader);
    const resetPage = usePaginationStore((state) => state.resetPage);
    const resetAll = useAdvancedSearch((state)=> state.resetAll)
    const setProgram = useApplicationFee((state) => state.setProgramId);
    const setAppId = useApplicationFee((state) => state.setAppId);
    
    const appStatusList = authQueries.useAppStaffStatusMutationList(
        (res)=>{
        }
    )
    useEffect(() => {
        appStatusList.mutateAsync({page: 0, size: 10000})
    }, [])

    const listApplicants = authQueries.useFeeCoordinatorMutationList(
        (response) => {
            setLoader(false)
            setResult(response)
        }
    );

    useEffect(() => {
        setLoader(true)
    }, [data, currentPage]);

    useEffect(() => {
        listApplicants.mutateAsync({
            page: currentPage,
            size: 25,
            ...data,
        })
    }, [data, apiData, currentPage])

    const expantButton = (index) => {
        if (active.index === index) {
            setActive({
                index: null,
                status: false
            })
        } else {
            setActive({
                index: index,
                status: true
            })
        }
    }

    const resetFilters =()=>{
        resetAll()
        resetPage()
    }

    const handleAdmissionFee =(items, item)=> {
		navigate(RouteConstants.ADMISSIONFEEORM)
		setProgram(items)
        setAppId(item)
	}

    return (
        <div>
            <div className="add_clients">
                <div className="row">
                    <div className="col-md-6 appointments-hing-wrpr">
                        <i className="appointments-icon">
                            <img src={require('../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                        </i>
                        <h2><span>My</span>Clients</h2>
                    </div>
                    <div className="col-md-12">
                        <hr className="progress-hr" />
                    </div>
                </div>

                <div id="accordion" className="add_clients_accordion_form">
                    <div className="card" id="appointments_wrpr">
                        <div className="card-header flex justify-between">
                            <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                                My Clients
                            </a>
                            <div>
                                <button className='text-[12px] flex gap-2 items-center' onClick={resetFilters}>
                                    <IconClearAll size={17} />
                                    Clear Filters
                                </button>
                            </div>
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">

                                <AdvancedSearch  myclient />

                                <div className="table-list-search-wrpr simple-search">
                                    <div className="search-results-count-wrpr">
                                        {
                                        result?.data?.totalItems > 0 ?
                                        `Search Results ${result?.data?.currentPage * 25 + 1}-${result?.data?.currentPage * 25 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                        `O results found`
                                        }
                                    </div>

                                    {
                                        loader ? 
                                        <LoaderCommon />:
                                        result?.data?.totalItems > 0 ?
                                        <div className="table-wrpr">
                                            <div className="table-section">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <table className="table prospect-table">
                                                            <thead>
                                                                <tr className='alt-colors'>
                                                                    <th width="30">#</th>
                                                                    <th>Profile Status</th>
                                                                    <th>Office</th>
                                                                    <th>Date Created</th>
                                                                    <th>Applicant Name</th>
                                                                    <th>Contact Number</th>
                                                                    <th>Visa Type</th>
                                                                    <th>Intake</th>
                                                                    <th>Reg. Date</th>
                                                                    <th>Prospect Status</th>
                                                                    <th>Referred By</th>
                                                                    <th width='150'>&nbsp;</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="ui-sortable">
                                                                {result?.data?.items?.map((element, index) => {
                                                                    const item = element?.application
                                                                    return(
                                                                        <Fragment key={index}>
                                                                            <tr>
                                                                                <td className="">{(index + 1) + (result.data?.currentPage * 25)}</td>
                                                                                <td>
                                                                                    <LeadStatus item={item} />
                                                                                </td>
                                                                                <td>{item?.office?.name}</td>
                                                                                <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                                <td>
                                                                                    {
                                                                                        item?.intended_programs?.length > 0 ?
                                                                                        <a style={{ cursor: 'pointer', color: '#1000FF' }} onClick={() => { expantButton(index) }}>
                                                                                            {decode(item?.applicant_name)} {decode(item?.middlename)} {decode(item?.lastname)} <i className='icon-arrow-down-thick'></i>
                                                                                        </a> :
                                                                                        <Fragment>{decode(item?.applicant_name)} {decode(item?.middlename)} {decode(item?.lastname)}</Fragment>
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <div className="email-mobile flex flex-col">
                                                                                        <div>{item.mobile_no}</div>
                                                                                        <div>{item.landline_no}</div>
                                                                                        <div>{item.whatsapp_no}</div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{item?.visatype?.description}</td>
                                                                                <td>{item?.intake?.intake}</td>
                                                                                <td>
                                                                                    {item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}
                                                                                </td>
                                                                                <td><ProspectStatusList item={item} /></td>
                                                                                <td>{item?.refferal?.refferal}</td>
                                                                                <td className='flex justify-end'>
                                                                                    <div className='grid grid-cols-12 gap-1 w-[160px]'>
                                                                                        <div className='col-span-9'>
                                                                                            <FollowUp item={item} />
                                                                                        </div>
                                                                                        <div className='col-span-3'>
                                                                                            <ApplicantViewButton item={item} />
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan="12" className={active.index === index && active.status ? "dropdown-table-wrpr active" : "dropdown-table-wrpr"}>
                                                                                    <table className="table dropdown-table">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Prioriy</th>
                                                                                                <th>College / University</th>
                                                                                                <th>Program Name</th>
                                                                                                <th>Intake</th>
                                                                                                <th>Application Status</th>
                                                                                                <th>Duration</th>
                                                                                                <th>Code</th>
                                                                                                <th>Status</th>
                                                                                                <th>Pending Fee</th>
                                                                                                <th>&nbsp;</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {item?.intended_programs
                                                                                            ?.sort((a, b) => a.priority - b.priority)
                                                                                            ?.map((items, indexes) => (
                                                                                                <tr key={indexes}>
                                                                                                    <td width="15">
                                                                                                        <div
                                                                                                        className='font-[600] flex items-center justify-center w-[25px] h-[25px] rounded-full text-white'
                                                                                                        style={{background: items?.status === "CONFIRMED" ? 'green' : 'orange'}}>
                                                                                                            {items?.priority}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{items?.college?.name}</td>
                                                                                                    <td>
                                                                                                        {items?.program?.programlink ?
                                                                                                        <a className='text-blue-700' href={items?.program?.programlink} target='_blank'>
                                                                                                            {items?.program?.name}
                                                                                                        </a>:
                                                                                                        <div>{items?.program?.name}</div>
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td>{items?.intake?.intake}</td>
                                                                                                    <td>
                                                                                                        <ApplicationStatusModal item={items} />
                                                                                                    </td>
                                                                                                    <td>{items?.program?.duration && `${items?.program?.duration} Year`}</td>
                                                                                                    <td>{items?.program?.program_code}</td>
                                                                                                    <td>
                                                                                                        <ApplicationStatusModal item={items} />
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <PendingFeeCalculate data={items} feetype={items?.admission_feetypes} />
                                                                                                    </td>
                                                                                                    <td className='flex justify-end'>
                                                                                                        <div className='flex flex-col gap-1 min-w-[240px]'>
                                                                                                            <div className='flex gap-1'>
                                                                                                                <div className='w-[80px]'>
                                                                                                                    <DocumentUploadModal item={items} />
                                                                                                                </div>
                                                                                                                <div className='w-[70px]'>
                                                                                                                    <PaymentHistoryModal item={items} />
                                                                                                                </div>
                                                                                                                <div className='w-[120px]'>
                                                                                                                    <ApplicationStatusCreateModal status={appStatusList?.data?.data?.items} item={items} application={item} />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className='flex gap-1'>
                                                                                                                <div className='w-[80px]'>
                                                                                                                    <button onClick={()=> handleAdmissionFee(items, item)} className="btn-customized" >Fee Details</button>
                                                                                                                </div>
                                                                                                                <div className='w-[70px]'>
                                                                                                                    <PaymentCreateModal item={items} />
                                                                                                                </div>
                                                                                                                <div className='w-[120px]'>
                                                                                                                    <LoginDetailsModal item={items} />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </Fragment>
                                                                    )}
                                                                )}
                                                            </tbody>
                                                        </table>
                                                        <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className='mt-24 mb-24'>
                                            <Empty
                                            description={<div className="text-sm text-blue-700" style={{fontFamily: "var(--primary-font)"}}>Clients not found!</div>}
                                            />
                                        </div>
                                    }

                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default FeeCoordinatorMyClients

const PendingFeeCalculate = (props) => {

    const [total, setTotal] = useState(0);

    useEffect(() => {

        if(props.data.sid === 9){
            const amt = 0
            setTotal(amt.toFixed(2));
            return;
        }

        let grandTotal = 0;
        let total = 0;
        
        props?.feetype?.forEach((admissionItem) => {
            grandTotal += parseFloat(admissionItem?.amount || 0);
            admissionItem?.feepaids?.forEach((feepaidItem) => {
                total += parseFloat(feepaidItem?.amount || 0);
            });
        });
        
        const pendingAmount = (grandTotal - total).toFixed(2);

        setTotal(pendingAmount)
    }, [props])

 
    return  (
        <div>
            {total}
        </div>
    )

}