import apiRequest from '../apis/api-request';
import { userStore } from '../store/userStore';

const referralClientService = {
   
    createReferralClient: async (data) => {
        return apiRequest({
            method: "POST",
            url: `/api/referral_clients`,
            data
        });
    },
   
    getReferralClient: async (data) => {
        const page = data.page || 0
        const size = data.size || 25
        const type = data.type || "new"
        return apiRequest({
            method: "GET",
            url: `/api/referral_clients?page=${page}&limit=${size}&type=${type}`
        });
    }

}

export default referralClientService