import { message, Popconfirm } from 'antd'
// import { decode } from 'html-entities'
import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import authQueries from '../../queries/authQueries';
import { userStore } from '../../store/userStore';
import { DeleteMessage } from '../Common/AlertMessage';
import $ from "jquery";
import { Pagination, PaginationItem } from '@mui/material';
import moment from 'moment';
import * as yup from 'yup';
import { Modal, useModal, Button, Text, Loading } from "@nextui-org/react";
import RouteConstants from '../../Constants/RouteConstans';


const ClientList = () => {

    const store = userStore()
    const [page, setPage] = useState(0);
    const [result, setResult] = useState([]);
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")
    const [status, setStatus] = useState(null)
    const [visa, setVisa] = useState(null)
    const [intake, setIntake] = useState(null)
    const [counselor, setCounselor] = useState(null)

    const navigate = useNavigate();

    useEffect(() => {
        setPage(0)
        clientListing.mutateAsync({
            page: 0, 
            size: 25,
            name: name,
            mobile: mobile,
            visa: visa,
            email: email,
            status: status,
            intake: intake,
            counselor: counselor,
        })
    }, [name, mobile, visa, email, status, counselor])

    useEffect(() => {
        visaListing.mutateAsync({ page: 0, size: 10000 })
        intakeListing.mutateAsync({ search:"", status:"ACTIVE", page: 0, size: 10000 })
        StatusofProspectusList.mutateAsync({search:"", status:"ACTIVE", page: 0, size: 10000 })
        listUser.mutateAsync(3)
    }, [])


    const visaListing = authQueries.useVisaTypeMutationList(
        (response) => {
        }
    );

    const intakeListing = authQueries.useIntakeMutationList(
        (response) => {
        }
    );

    const StatusofProspectusList = authQueries.useProspectList(
        (response) => {
        }
    );

    const listUser = authQueries.useUserListMutation(
        (response) => {
        }

    );

    const clientListing = authQueries.useClientList(
        (response) => {
            setResult(response)
        }
    );


    const handleChange = (event, value) => {
        setPage(value-1)
        clientListing.mutateAsync({
            page: value - 1, 
            size: 25,
            name: name,
            mobile: mobile,
            visa: visa,
            email: email,
            status: status,
            intake: intake,
            counselor: counselor,
        })
    }


    const editApplicant =(id, geebee)=>{
        store.setApplication(id)
        viewApplication.mutateAsync(id)
    }

    const viewApplication = authQueries.useApplicantMutationView(
        (response) => {
            let val = response?.data
                  
            const dataValues = {
                appName: val.applicant_name,
                middlename: val.middlename,
                lastname: val.lastname,
                gender: val.gender,
                marital: val.marital_status,
                appEmail: val.email,
                mobile:val.mobile_no,
                image: val.photo,
                whtsapp: val.whatsapp_no,
                landline: val.landline_no,
                appOccu:val.occupation,
                appDob:val.dob,
                age:val.age,
                appQualification:val.qid,
                passport_status: val.passport_status,
                noofChild:val.no_of_children,
                passno:val.passport_no,
                aadhno:val.aadhar_no,
                visatype:val.visa_type_id,
                intake:val.intake_id,
                reffered:val.referral_id,
                office:val.office_id,
                spoName: val?.spouse?.spouse_name,
                lead_status: val?.lead_status,
                spoAadhno:val?.spouse?.aadhar_no,
                spouse_status:val?.spouse_status,
                spoAge: val?.spouse?.age,
                spoDob: val?.spouse?.dob,
                spoOccu: val?.spouse?.occupation,
                spoQualification: val?.spouse?.qid,
                country: val?.visatype?.country_id,
                geebee_id: val?.geebee_student_id,
                age1: val?.childrens[0]?.age,
                age2: val?.childrens[1]?.age,
                age3: val?.childrens[2]?.age,
                loan: val?.loan,
                interview: val?.interview,
                travel: val?.travel,
                gic: val?.gic,
                registered_status:val?.registered_status
            }
      
            store.setApplicantData(dataValues)
            
            navigate(RouteConstants.ADDCLIENT)
      
        }   
    );      

    return (
        <div>


            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Clients</li>
                    </ol>
                </nav>
             
            </div>

            <div className="add_clients" style={{borderBottom:'0px'}}>
                <div className="row">
                    <div className="col-md-6 appointments-hing-wrpr">
                        <i className="appointments-icon">
                            <img src={require('../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                        </i>
                        <h2><span>My</span>Clients</h2>

                    </div>
                    <div className="col-md-12">
                        <hr className="progress-hr" />
                    </div>
                </div>

                <div id="accordion" className="add_clients_accordion_form">

                    <div className="card" id="appointments_wrpr">
                        <div className="card-header">
                            <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                                &nbsp;
                            </a>

                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className="row ">

                                    <div className="col-md-2 pr-0">
                                        <div className="form-group">
                                            <label htmlFor="">Name:</label>
                                            <input onChange={(e)=>{setName(e.target.value)}}  name="name" id="" type="text" className="form-control" />

                                        </div>
                                    </div>
                                    <div className="col-md-2 pr-0">
                                        <div className="form-group">
                                            <label htmlFor="">Email:</label>
                                            <input onChange={(e)=>{setEmail(e.target.value)}}  name="email" id="" className="form-control" />

                                        </div>
                                    </div>
                                    <div className="col-md-1 pr-0">
                                        <div className="form-group">
                                            <label htmlFor="">Phone No:</label>
                                            <input onChange={(e)=>{setMobile(e.target.value)}}  name="mobile" id="" type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-2 pr-0">
                                        <label>Status</label>
                                        <select onChange={(e)=>{setStatus(e.target.value)}}  name='status' className="form-control" style={{ minHeight: '40px' }}>
                                            <option value="">Select...</option>
                                            {
                                                StatusofProspectusList?.data?.data?.items?.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.id}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-2 pr-0">
                                        <label>Visa Type</label>
                                        <select onChange={(e)=>{setVisa(e.target.value)}}  name='visa' className="form-control" style={{ minHeight: '40px' }}>
                                            <option value="">Select...</option>
                                            {
                                                visaListing?.data?.data?.items.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.id}>{item.description}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-1 pr-0">
                                        <label>Intake</label>
                                        <select onChange={(e)=>{setIntake(e.target.value)}}  name='intake' className="form-control" style={{ minHeight: '40px' }}>
                                            <option value="">Select...</option>
                                            {
                                                intakeListing?.data?.data?.items?.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.id}>{item.intake}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <label>Counselor</label>
                                        <select onChange={(e)=>{setCounselor(e.target.value)}} name="counselor" className="form-control" style={{ minHeight: '40px' }}>
                                            <option value="">Select...</option>
                                            {
                                                listUser?.data?.data?.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.id}>{item?.firstname} {item?.lastname}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>


                                    <div className="col-md-12 search-results-count-wrpr">
                                        Search Results {clientListing?.data?.data?.items?.length} of {clientListing?.data?.data?.totalItems}
                                    </div>
                                    {
                                        clientListing.isLoading ? 
                                        <div style={{display:'flex', justifyContent:'center', flexDirection:'column', width:'100%', marginTop:'5%', marginBottom:'5%'}}>
                                            <Loading  />
                                            <span style={{marginTop:'10px', textAlign:'center', fontSize:'12px', color:'#888'}}>Loading...</span>
                                        </div>:
                                        <div className="col-md-12">

                                            <table className="table collageprogram">
                                                <thead>
                                                    <tr>
                                                        <th width="30">#</th>
                                                        <th>Counselor</th>
                                                        <th>Name</th>
                                                        <th>Status</th>
                                                        <th>Phone No.</th>
                                                        <th>Visa&nbsp;Type</th>
                                                        <th>Office</th>
                                                        <th>Active Intake</th>
                                                        <th>Regd.&nbsp;Date</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="ui-sortable">
                                                    {clientListing?.data?.data?.items?.map((item, index) => (

                                                        <Fragment key={index}>
                                                            <tr className="ui-sortable-handle">
                                                                <th className="">{(index + 1) + (result?.data?.currentPage * 25)} </th>
                                                                <td>
                                                                    {
                                                                        item?.assign_to_counsellors?.length > 0 &&
                                                                            item?.assign_to_counsellors[0]?.user?.userdetail?.firstname + ' ' + item?.assign_to_counsellors[0]?.user?.userdetail?.lastname
                                                                    }
                                                                </td>
                                                                <td>{item?.applicant_name} {item?.middlename} {item?.lastname}</td>
                                                                <td>{item?.status_of_prospect?.name}</td>
                                                                <td>+{item.mobile_no}</td>
                                                                <td>{item?.visatype?.description}</td>
                                                                <td>{item?.office?.name}</td>
                                                                <td>{item?.intake?.intake}</td>
                                                                <td><span className='registered'>{item.registered_date && moment(item?.registered_date).format('DD-MM-YYYY')}</span></td>
                                                                <td className="action-icons">
                                                                    <button onClick={()=>{editApplicant(item.id, item.geebee_student_id)}} className="btn-visadata">Edit Applicant</button>
                                                                </td>
                                                            </tr>
                                                        </Fragment>

                                                    ))}

                                                </tbody>
                                            </table>
                                            {
                                                clientListing?.data?.data?.totalPages > 1 &&
                                                    <ul className="pagination pagination-sm justify-content-center table-pagination mt-5 custom-pagination">
                                                        <Pagination
                                                            count={clientListing?.data?.data?.totalPages}
                                                            renderItem={
                                                                (item) => (

                                                                    <PaginationItem component="li" {...item} />
                                                                )
                                                            }
                                                            variant="outlined"
                                                            page={clientListing?.data?.data?.currentPage + 1}
                                                            shape="rounded"
                                                            onChange={handleChange}
                                                        />
                                                    </ul>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
             
            </div>


        </div>
    )

}

export default ClientList
