const RouteConstants = { 
    ROOT: '/',
    DASHBOARD:'/dashboard',
    ADMINDASHBOARD:'/admindb',
    MANAGERDASHBOARD:'/managerdb',
    APPLICANTDASHBOARD:'/applicantstaffdashboard',
    USERLIST: '/userlist/',
    USERADD: '/useradd/',
    USEREDIT: '/useredit/:uid',
    COLLEGE:'/college',
    COUNTRY:'/country',
    NOC:'/noc',
    OFFICE:'/office',
    PROGRAM:'/program',
    QUALIFICATION:'/qualification',
    VISATYPE:'/visatype',
    DISTRICT:'/district',
    REFFERAL:'/refferal',
    SECONDARYREFFERAL:'/secondary_refferal',
    PNP:'/pnp',
    INTAKE:'/intake',
    ROLE:'/role',
    UNIVERSITY:'university',
    USERDETAILS:'userdetails',
    ADDCLIENT:'/addclient',
    ADDPROGRAM:'/addprogram',
    ADDQUALIFICATION:'/addqualification',
    ADDREFFERAL:'/addrefferal',
    ADDROLE:'/addrole',
    ADDUNIVERSITY:'/adduniversity',
    ADDVISATYPE:'/addvisatype',
    EDITPROGRAM: '/editprogram',
    EDITQUALIFICATION:'/editqualification',
    EDITREFFERAL:'/editrefferal',
    EDITROLE:'/editrole',
    EDITUNIVERSITY:'/edituniversity',
    EDITVISATYPE:'/editvisatype',
    ADDCOLLEGE:'/addcollege',
    VIEWCOLLEGE:'/viewcollege',
    EDITCOLLEGE:'/editcollege',
    ADDCOUNTRY:'/addcountry',
    VIEWCOUTRY:'/viewcountry',
    EDITCOUNTRY:'/editcountry',
    ADDDISTRICT:'/adddistrict',
    VIEWDISTRICT:'/viewdistrict',
    EDITDISTRICT:'/editdistrict',
    ADDINTAKE:'/addintake',
    VIEWINTAKE:'/viewintake',
    EDITINTAKE:'/editintake',
    ADDNOC:'/addnoc',
    VIEWNOC:'/viewnoc',
    EDITNOC:'/editnoc',
    ADDOFFICE:'/addoffice',
    VIEWOFFICE:'/viewoffice',
    EDITOFFICE:'/editoffice',
    ADDPNP:'/addpnp',
    VIEWPNP:'/viewpnp',
    EDITPNP:'/editpnp',
    ADDINFO: '/addinfo',
    LISTINFO: '/listinfo',
    VIEWINFO: '/viewinfo',
    EDITINFO: '/editinfo',
    ADDAPPOINT: '/addappoint',
    EDITAPPOINT: '/editappoint',
    LISTAPPOINT: '/listappoint',
    ADDDOC: '/adddoc',
    EDITDOC: '/editdoc',
    LISTDOC: '/listdoc',
    VIEWDOC: '/viewdoc',
    ADDPROSPECT: '/addprospect',
    EDITPROSPECT: '/editprospect',
    LISTPROSPECT: '/listprospect',
    VIEWPROSPECT: '/viewprospect',
    ADDPGDURATION: '/addpgd',
    EDITPGDURATION: '/editpgd',
    LISTPGDURATION: '/listpgd',
    VIEWPGDURATION: '/viewpgd',
    ADDFOLLOWDURATION: '/addfld',
    EDITFOLLOWDURATION: '/editfld',
    LISTFOLLOWDURATION: '/listfld',
    VIEWFOLLOWDURATION: '/viewfld',
    APPLICANTASSIGNLIST: '/applicant_assign_list',
    FORGOTPWD: '/forgot_password',
    ADDTELECALLER: '/addlead',
    LISTTELECALLER: '/listtelecaller',
    ADMISSIONFEEORM: '/admission_fee',
    APPLICANTDETAILPAGE: '/applicantdetails',
    COUNSELORREPORT: '/report',
    CLIENTLIST: '/all/clientlist',
    UNIQUESTATUS: '/report/uniquestatus',
    ADDCAMPUS: '/addcampus',
    EDITCAMPUS: '/editcampus',
    LISTCAMPUS: '/listcampus',
    TARGET: '/target',
    IMPORT: '/import',
    ADDAPPSTAFFSTATUS:'/addappstaffstatus',
    VIEWAPPSTAFFSTATUS:'/viewappstaffstatus',
    EDITAPPSTAFFSTATUS:'/editappstaffstatus',
    LISTAPPSTAFFSTATUS:'/listappstaffstatus',
    CLIENTADD: '/clientadd',
    ADDFEEPAIDTYPE: '/addfeepaidtype',
    EDITFEEPAIDTYPE: '/editfeepaidtype',
    LISTFEEPAIDTYPE: '/listfeepaidtype',
    VIEWFEEPAIDTYPE: '/viewfeepaidtype',
    ADDLEADTYPE: '/addleadtype',
    EDITLEADTYPE: '/editleadtype',
    LISTLEADTYPE: '/listleadtype',
    VIEWLEADTYPE: '/viewleadtype',
    EMPLOYEE: '/report/employee',
    COUNSELOREGISTRATION: '/report/counselor',
    USERBRANCH: '/user_list',
    USERSLIST: '/clientlist',
    COUNTRYWISE:'/report/countrywise',
    FEECOORD: '/feepayment',
    UNIQUECOUNTRYWISE:'/report/uniquecountrywise',
    NOTINTREPORT: '/report/notinterested',
    NOTQUALREPORT: '/report/notqualified',
    FEEPAID: '/report/feepaid',
    VISAAPPROVED: '/report/visaapproved',
    CALENDAR: '/calendar',
    LEADLIST: '/leadlist',
    SEARCHCLIENTS: '/searchclients',
    BRANCHWISE:'/report/branchwise',
    BTARGET:'/report/btarget',
    CTARGET:'/report/ctarget',
    FEESTATUS:'/report/feestatus',
    EXPORTCLIENT: '/exportclient',
    BMLIST: '/leads_list',
    LOAN: '/loan',
    TRAVEL: '/travel',
    PDFFORM: '/export/form',
    DAILYREPORT: '/daily_report',
    COUNSELORASSOCIATES: '/counselor_associates',
    NOTINTEREST: '/not_interested',
    NOTQUALIFIED: '/not_qualified',
    LONGTERM: '/longterm',
    ONHOLD: '/onhold',
    NOTPROCEEDINGOFFER: '/offer_notproceeding',
    STUDENTCONFIRM: '/applicant_confirmation/:data',
    ERRORPAGE: '/404',
    PROGRAMSUGGESTION: '/program_suggestion',
    TOPPERFORMER: '/report/top_performers',
    ASSOCIATEREPORT: '/report/associates_report',
    FINISHEDCLIENTS: '/finished_clients',
    VISAFINISHEDCLIENTS: '/visa/finished_clients/',
    OFFICEFINISHEDCLIENTS: '/office/finished_clients/',
    COLORS: '/colors',
    RESETPASSWORD: '/reset_password',
    ADMISSIONSTAFFMYCLIENTS: '/myclients_admission',
    SENIORMANAGERPROGRAMMASTER: '/senior_manager_program_master',
    SENIORMANAGERUSERLIST: '/senior_manager_users',
    SENIORPROGRAMMANAGERMYCLIENT: '/myclients_senior_program_coordinator',
    MARKREGISTRATIONMYCLIENTS: '/myclients_markregister',
    PROGRAMMYCLIENTS: '/myclients_program',
    TELECALLERMYCLIENTS: '/myclients_bda',
    VISASTAFFMYCLIENTS: '/myclients_visastaff',
    DOCUMENTSTAFFMYCLIENTS: '/myclients_documentstaff',
    FEECOORDINATORMYCLIENTS: '/myclients_fee_coordinator',
    SOPSTAFFMYCLIENTS: '/myclients_sop',
    PENDINGPROGRAMLIST: '/pending_programs',
    RMMYCLIENTS: '/myclients_rm',
    NOTIFICATIONS: '/notifications',
    ADMISSIONSTAFFREPORT: '/report/admission_staff',
    PGMSUGGESTIONREPORT: '/report/pgm_suggestion',
    MYCLIENTSSENIORPROGRAMCOORDINATOR: '/myclients_senior_program_coordinator/assigned',
    COUNSELORNIS: '/report/counselornis',
    COUNTRYPERFOMANCE: '/report/countryperformance',
    ONHOLDREPORT: '/report/onholdreport',
    READYTOMARK: '/report/readytomark',
    WALKIN: '/walkin',
    WALKINDATA: '/walkindata',
    WALKINEDITFORM: '/walkineditform',
    NOTPROCEEDING: '/report/notproceeding',
    OFFICEVISA: '/report/officevisa',
    OFFICEFEEPAID: '/report/officefeepaid',
    COMMISSIONREPORT: '/commissionreport',
    COUNSELORSCORE: '/score',
    CLIENTEXPORT: '/report/clientexport',
    COUNSELORSCORE: '/score',
    USAPROGRAM: '/usaprogram',
    USALOCATION:'/usaprogramlocations',
    USACITY:'/usaprogramcity',
    USALEVEL:'/usaprogramlevel',
    COUNTRYWISENEW:'/report/countrywisenew',
    LOGOUTALLUSER: '/logoutalluser',
    COLLEGEMARKETING: '/college_marketing',
    EVENTLIST: '/events',
    DISTRIBUTIONREPORT: '/distribution_report',
    CALLCENTERAPPLICANT: '/call_center_applicant',
    ALLFOLLOWUPPENDING: '/pending_followup',
    APPLICATIONMANAGEMENTREPORT: '/applicationmanagement',
    SMARTLOG: '/smart_log',
    NOTINTERESTEDREASON: '/not_interested_reason',
    CALLLOGREPORT: '/call_log_report',
    WALKINFORM:'/walkinform',
    WALKINREPORT:'/walkinreport',
    LEADSTATUS:'/leadstatus',
    LEADSTATUSREPORT:'/leadstatusreport',
    LEADCOUNTRY:'/leadcountry',
    REFERRALCLIENTS: '/referral_leads',
    FEEPAIDBRANCH: '/feepaid_branch',
    FRESHWALKIN:'/freshwalkin',
    EXISTNGWALKIN:'/existingwalkin',
    REFERRALCLIENTS: '/referral_clients',
};

export default RouteConstants
