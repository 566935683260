
import React from "react";
import Sidebar from "./Sidebar";
import SocketLogout from "../../../socket/SocketLogout";
import EventListModal from "../EventListModal";
import CallCenterModal from "../../../socket/SocketCallCenter";
import SocketCallCenter from "../../../socket/SocketCallCenter";

const PageLayout = () => {
  return (
    <div>
        <SocketLogout />
        <Sidebar />
        <EventListModal />
        <SocketCallCenter />
    </div>
  );
}

export default PageLayout;