import { IconUserEdit, IconUserPlus } from "@tabler/icons-react";
import { Empty } from "antd";
import { GanttChartSquare } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import RouteConstants from "../../Constants/RouteConstans";
import referralClientQueries from "../../queries/referralClientQueries";
import {
  usePaginationStore,
  userStore,
  useViewProfile,
} from "../../store/userStore";
import ApplicantViewButton from "../Common/ApplicantViewButton";
import LoaderCommon from "../Common/LoaderCommon";
import PaginationComponent from "../Common/PaginationComponent";
import { motion } from "framer-motion";


const ReferralClients = () => {

    const navigate = useNavigate();
    const store = userStore();

    const [result, setResult] = useState([]);
    const { currentPage, setCurrentPage } = usePaginationStore();
    const { setAppId } = useViewProfile();
    const [leadType, setLeadType] = useState("new");

    // Fetch Data
    const getData = referralClientQueries.useGetReferralClient((res) =>
        setResult(res?.success ? res : [])
    );

    useEffect(() => {
        getData.mutateAsync({ page: currentPage, limit: 25, type: leadType });
    }, [leadType]);

    // Format Applicant Data
    const formatApplicantData = (item) => {
        const nameParts = item.applicant_name.trim().split(/\s+/);
        return {
            applicant_name: nameParts[0] || "",
            middlename: nameParts.length > 2 ? nameParts.slice(1, -1).join(" ") : "",
            lastname: nameParts.length > 1 ? nameParts[nameParts.length - 1] : "",
            mobile_no: item.mobile_no,
            email: item.email,
            visa_type_id: item.visa_type_id,
            intake_id: item.intake_id,
            ref_client_id: item.id,
            referral_id: 1,
        };
    };

    // Handle Add Client
    const handleAddClient = async (item) => {
        if (item?.applicant_id) {
            await store.setApplication(item.applicant_id);
            await store.setApplicationAllData({});
        } else {
            await store.setApplication("");
            await store.setApplicationAllData({ applicant: formatApplicantData(item) });
        }
        navigate(RouteConstants.ADDCLIENT);
    };

    // Handle View Profile
    const handleChange = (item) => {
        const id =
        leadType === "saved"
            ? item?.referrals?.referredBy?.id
            : item?.referredBy?.id;
        setAppId(id);
        navigate(RouteConstants.APPLICANTDETAILPAGE);
    };

    return (
        <div>
        {/* Breadcrumb */}
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">Referral Leads</li>
            </ol>
        </nav>

        {/* Header */}
        <div className="add_clients">
            <div className="flex items-center gap-2 mb-4">
                <GanttChartSquare size={25} className="text-[#7D6FAB]" />
                <span className="text-[16px] font-medium">Referral Leads</span>
            </div>

            <div className="inline-flex items-center p-1 bg-white rounded-lg shadow-sm mb-3">
                {["new", "saved"].map((type) => (
                    <div key={type} className="relative">
                    <motion.button
                        className={`relative z-10 px-4 py-2 text-sm font-medium rounded-md whitespace-nowrap ${
                        leadType === type 
                            ? 'custom-color text-white' 
                            : 'text-gray-500 hover:text-gray-700'
                        }`}
                        onClick={() => setLeadType(type)}
                        whileHover={{ scale: leadType !== type ? 1.05 : 1 }}
                        whileTap={{ scale: 0.95 }}
                        transition={{ type: "spring", stiffness: 400, damping: 17 }}
                    >
                        {type === "new" ? "New Leads" : "Saved Leads"}
                    </motion.button>
                    
                    {leadType === type && (
                        <motion.div 
                        className="absolute inset-0 bg-gray-100 rounded-md"
                        layoutId="tab-background"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ 
                            type: "spring", 
                            stiffness: 500, 
                            damping: 30,
                            duration: 0.35 
                        }}
                        />
                    )}
                    </div>
                ))}
            </div>
            {/* Results Count */}
            <div className="search-results-count-wrpr">
            {result?.data?.totalItems > 0
                ? `Search Results ${result?.data?.currentPage * 100 + 1}-${
                    result?.data?.currentPage * 100 + result?.data?.items?.length
                } of ${result?.data?.totalItems}`
                : `0 results found`}
            </div>

            {/* Table or Loader */}
            {getData.isLoading ? (
            <LoaderCommon />
            ) : result?.data?.items?.length > 0 ? (
            <div className="table-wrpr">
                <div className="table-section">
                    <div className="row">
                        <div className="col-md-12">
                            <table className="table prospect-table !text-[14px]">
                                <thead>
                                    <tr>
                                        <th width="2%">#</th>
                                        <th>Intake</th>
                                        <th>Visa Type</th>
                                        <th>Name</th>
                                        <th>Contact No</th>
                                        <th>Email</th>
                                        {leadType === "saved" && (
                                            <>
                                            <th>Office</th>
                                            <th>Prospect Status</th>
                                            </>
                                        )}
                                        <th>Referred Applicant</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className="text-capitalize">
                                    {result?.data?.items?.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index + 1 + result.data?.currentPage * 25}</td>
                                        <td>{item?.intake?.intake}</td>
                                        <td>{item?.visatype?.description}</td>
                                        <td>{`${item?.applicant_name} ${
                                        item?.middlename || ""
                                        } ${item?.lastname || ""}`}</td>
                                        <td>
                                        <div className="flex flex-col gap-2">
                                            <span>{item?.mobile_no}</span>
                                            {item?.whatsapp_no && <span>{item?.whatsapp_no}</span>}
                                            {item?.landline_no && <span>{item?.landline_no}</span>}
                                        </div>
                                        </td>
                                        <td>{item?.email}</td>
                                        {leadType === "saved" && (
                                        <>
                                            <td>{item?.office?.name}</td>
                                            <td>{item?.status_of_prospect?.name}</td>
                                        </>
                                        )}
                                        <td>
                                        <button className="text-blue-700" onClick={() => handleChange(item)}>
                                            {leadType === "saved"
                                            ? `${item?.referrals?.referredBy?.applicant_name || ""} ${
                                                item?.referrals?.referredBy?.middlename || ""
                                                } ${item?.referrals?.referredBy?.lastname || ""}`
                                            : `${item?.referredBy?.applicant_name || ""} ${
                                                item?.referredBy?.middlename || ""
                                                } ${item?.referredBy?.lastname || ""}`}
                                        </button>
                                        </td>
                                        <td className="flex justify-end">
                                        {
                                            leadType === "saved" ?
                                            <div className="min-w-[80px]">
                                                <ApplicantViewButton item={item} />
                                            </div>:
                                            <button
                                                className="btn-customized-large h-8 gap-2"
                                                style={{
                                                backgroundImage: item?.applicant_id
                                                    ? "linear-gradient(to top, #FFC107, #FF9800)"
                                                    : "",
                                                }}
                                                onClick={() => handleAddClient(item)}
                                            >
                                                {item?.applicant_id ? <IconUserEdit size={16} /> : <IconUserPlus size={16} />}
                                                {item?.applicant_id ? "Edit as client" : "Add as client"}
                                            </button>
                                        }
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                            <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                        </div>
                    </div>
                </div>
            </div>
            ) : (
            <Empty description={<span className="text-sm text-blue-700">Leads not found!</span>} />
            )}
        </div>
        </div>
    );
};

export default ReferralClients;
