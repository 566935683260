import { Checkbox, Modal, Textarea } from '@nextui-org/react'
import { message } from 'antd'
import React, { Fragment, useState } from 'react'
import authQueries from '../../queries/authQueries'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { generateValidationSchema } from './validationSchema'
import { useApi } from './ApiProvider'
import toast from 'react-hot-toast'

const   ConfirmProgram = ({item, applicant}) => {

    const [open, setOpen] = useState(false)
    const [isVisibleCounselor, setIsVisibleCounselor] = useState(false)

    const inputFields = [
        {
            name: "comment",
            label: "Comment",
            required: true
        },
        {
            name: "counselor_comment",
            label: "Comment for send to counselor",
            required: isVisibleCounselor
        }
    ]


    const { setApiDataAndNotify } = useApi()

    const { handleSubmit, register, formState: {errors}, setValue } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    })

    const handleOpen =()=> {
        setOpen(true)
        setIsVisibleCounselor(false)
    }

    const handleClose =()=> {
        setOpen(false)
        setIsVisibleCounselor(false)
    }

    const onSubmit =(data)=> {
        const dataValues = {
            id: item?.id,
            comment: data?.comment,
            status: "CONFIRMED"
        }
        const prospect = {
            applicant_id: item?.applicant_id,
            prospects_id: 11,
            comments: data?.counselor_comment,
            status:"ACTIVE"
        }
        programConfirm.mutateAsync(dataValues)
        if(isVisibleCounselor && data?.counselor_comment && applicant?.pros_status === 5){
            addProspect.mutateAsync(prospect)
        }
    }

    const programConfirm = authQueries.useIntProgramConfirm(
        (response) => {
            setApiDataAndNotify(response)
            setValue('comment', '');
            handleClose()
            setIsVisibleCounselor(false)
            toast.success('Program confirmed successfully')
        }   
    );

    const addProspect = authQueries.useProStatusAdd(
        (response) => {
        }
    );


  return (
    <Fragment>
        <button 
        disabled={item?.status  === "CONFIRMED" ? true:false}
        onClick={handleOpen}
        className={`btn-customized ${item?.status !== "CONFIRMED" && "hover:scale-110"}`}
        style={{opacity : item?.status === "CONFIRMED" && "0.7"}}
        >
        {item?.status === "CONFIRMED" ? 'Confirmed':'Confirm'}
        </button>
        <Modal
            open={open}
            closeButton
            onClose={handleClose}
            className="followup-modal"
            centered
            blur
            animationDuration={1000}
            overlayId="modal-overlay"
            width='700px'
        >
            <Modal.Header>
                <h5 className="modal-title">Program Confirmation Comments</h5>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='w-full mb-2'>
                        <textarea
                        {...register('comment')}
                        name='comment'
                        className="bg-white border border-solid border-gray-300 rounded-md w-full h-10 p-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 min-h-[100px]" 
                        rows={6} 
                        placeholder='Enter comment' 
                        />
                        {errors?.comment && <div className='text-red-500 text-[14px]'> {errors?.comment?.message}</div>}
                    </div>
                    {
                        applicant?.pros_status === 5 &&
                        <Fragment>
                            <div className='w-full mb-2'>
                                <Checkbox onClick={()=> setIsVisibleCounselor(!isVisibleCounselor)} label={<div className='text-[13px] font-normal'>Do you want to need send to counselor?</div>} />
                            </div>
                            {
                                isVisibleCounselor &&
                                <div className='w-full mb-2'>
                                    <textarea
                                    {...register('counselor_comment')}
                                    name='counselor_comment'
                                    className="bg-white border border-solid border-gray-300 rounded-md w-full h-10 p-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0 min-h-[100px]" 
                                    rows={6} 
                                    placeholder='Enter comment for send to counselor' 
                                    />
                                    {errors?.counselor_comment && <div className='text-red-500 text-[14px]'> {errors?.counselor_comment?.message}</div>}
                                </div>
                            }
                        </Fragment>
                    }
                    <div className='w-full flex justify-end'>
                        <div className='w-[140px]'>
                            <button 
                            type='submit' 
                            className="btn-customized-large"
                            >Confirm Program</button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    </Fragment>
  )
}

export default ConfirmProgram
