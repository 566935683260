import { useState, useEffect } from 'react';
import { AutoCompleteAntd, AutoCompleteAntdOffice, AutoCompleteChilds } from '../Clients/AddClients/AutoComplete'
import PhoneInput from 'react-phone-input-2'
import parsePhoneNumberFromString from 'libphonenumber-js';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import authQueries from '../../queries/authQueries';
import { userStore } from '../../store/userStore';
import toast from 'react-hot-toast';
import moment from 'moment'

const existingValidationSchema = yup.object({
    phone: yup.string().required('⚠ Phone Number is required'),
    //purpose: yup.string().required('⚠ purpose is required'),
    migration_type: yup.string().when('purpose', {
        is: 'Migration',
        then: () => yup.string().required('⚠ Migration Type is required'),
    }),
    holiday_type: yup.string().when('purpose', {
        is: 'Holidays',
        then: () => yup.string().required('⚠ Holiday Type is required'),
    }),

    assign: yup.string().when('pros_status', {
        is: 2,
        then: () => yup.string().required('⚠ Assign to Counselor is required'),
    }),
     comments: yup.string().when('purpose', {
         is: 'Others',
         then: () => yup.string().required('⚠ Comment is required'),
     }),   

});

export default function ExistingForm() {
    const store = userStore()
    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [office, setOffice] = useState(store?.user?.office[0]?.oid);
    const [prosStatus, setProsStatus] = useState('');
    const [officeData, setOfficeData] = useState([]);
    const [users, setUsers] = useState([]);
    const [formData, setFormData] = useState({});
    const [allStaff, setAllStaff] = useState([]);
    const [prospects, setProspects] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [code, setCode] = useState('in');
    const [codeLandline, setCodeLandline] = useState('in')
    const [mobile, setMobile] = useState('');
    const [mobileValid, setMobileValid] = useState('')
    const [visa, setVisaId] = useState('');
    const [visaData, setVisaData] = useState([]);
    const [migrationType, setMigrationType] = useState('');
    const [showMigrationType, setShowMigrationType] = useState(false);
    const [purpose, setPurpose] = useState('');
    const [disableVisaType, setDisableVisaType] = useState(false);

    const [holidayType, setHolidayType] = useState('');
    const [showHolidayType, setShowHolidayType] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const existingData = location.state?.data;
    const [comments, setComments] = useState('');
    console.log("existingData", existingData);

    useEffect(() => {
       
        if (existingData) {
            existingSetValue('fname', existingData?.applicant_name)     
            existingSetValue('lname', existingData?.lastname)
            existingSetValue('phone', existingData?.mobile_no)
            existingSetValue('mname', existingData?.middlename)
            existingSetValue('landline_no', existingData?.landline_no);
            existingSetValue('email', existingData?.email)
            //existingSetValue('office_id', store?.user?.office[0]?.oid)
            //existingSetValue('assign', existingData?.assignUser?.CounselorAssign?.userdetail?.user_id)
            existingSetValue('attendedby', existingData?.walkin_attendedBy)
            existingSetValue('pros_status', existingData?.pros_status)
            setVisaId(existingData?.visa_type_id);
        }
    }
        , [existingData])  
            

    useEffect(() => {
        officeList.mutateAsync({ search: '', status: '', page: 0, size: 500 });
        prospectList.mutateAsync({ search: '', status: '', page: 0, size: 500 });
        handleOfficeSelect();
    }, [])

    useEffect(() => {
        
        if(office){
            handleOfficeSelect();
        }
       
    }, [office])
    

    const {
        register: existingRegister,
        handleSubmit: existingHandleSubmit,
        setValue: existingSetValue,
        control: existingControl,
        formState: { errors: existingErrors }
    } = useForm({
        resolver: yupResolver(existingValidationSchema)
    });


    const handlePurposeChange = (e) => {
        console.log("e", e.target.value)
        const value = e.target.value;
        existingSetValue("purpose", value);
        setPurpose(value);
        // Show migration type dropdown if Purpose is Migration
        
        setShowMigrationType(value === "Migration");
       
        setShowHolidayType(value === "Holidays");
        
        // Reset migration type if purpose is changed to something else
        if (value !== "Migration") {
            setMigrationType('');
            existingSetValue("migration_type", '');
        }

        if (value !== "Holidays") {
            setHolidayType('');
            existingSetValue("holiday_type", '');
        }
    };

    function formatCurrentDateTime() {
        const now = new Date();
        
        // Get day of month and add suffix (1st, 2nd, 3rd, etc.)
        const day = now.getDate();
        let daySuffix = 'th';
        if (day === 1 || day === 21 || day === 31) {
            daySuffix = 'st';
        } else if (day === 2 || day === 22) {
            daySuffix = 'nd';
        } else if (day === 3 || day === 23) {
            daySuffix = 'rd';
        }
        
        // Get month as 3-letter abbreviation
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = months[now.getMonth()];
        
        // Get full year
        const year = now.getFullYear();
        
        // Get hours (12-hour format) and AM/PM
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // Hour '0' should be '12'
        
        // Get minutes with leading zero if needed
        const minutes = String(now.getMinutes()).padStart(2, '0');
        
        // Combine all parts into the final format
        return `${day}${daySuffix} ${month} ${year} ${hours}:${minutes} ${ampm}`;
    }

    const onExistingSubmit = async (data) => {
        try {
        setIsLoading(true);
        setComments(data.comments);
        console.log("data", data.comments);
        const dataValues = {
            applicant_id: existingData?.id,
            first_name: data.fname,
            last_name: data.lname,
            phone: data.phone,
            email: data.email,
            middle_name: data.mname,
            landline_no: data.landline_no,
            purpose: data.purpose,
            office_id: office,
            assign_to_counselor: data?.assign ? data?.assign: existingData?.assignUser?.CounselorAssign?.userdetail?.user_id,
            file_owned_counselor: existingData?.assignUser?.CounselorAssign?.userdetail?.user_id,
            created_by: store?.user?.id,
            walkin_status: "EXISING",
            walkin_attendedBy: data.attendedby,
            migration_type: data.purpose === "Migration" ? data.migration_type : null,
            holiday_type: data.purpose === "Holidays" ? data.holiday_type : null,
            visa_type_id: visa,
        };
        if(!office){
            dataValues.office_id = existingData?.office?.id
        }
        const response =  await AddWalkin.mutateAsync(dataValues);

        if(response?.message === "Success"){

            const selectedOffice = officeData.filter((off)=> off.value === office)

            const DefaultFollowupData = {
                applicant_id: response?.appData?.id,
                assign: existingData?.assignUser?.CounselorAssign?.userdetail?.user_id,
                comments: `${data.fname} ${data.mname} ${data.lname} attended a walk-in at the  ${selectedOffice[0]?.label} office`,
                type: "FOLLOW-UP",
                followupdate: moment().format("YYYY-MM-DD"),
                date_enable: "NO",
                status: "ACTIVE"
            };
            
            await addFollowupMutation.mutateAsync(DefaultFollowupData);

        }

        if(response?.message === "Success" && data?.assign){


            const capturedFollowupData = {
                applicant_id: response?.appData?.id,
                assign: store?.user?.id,
                comments: `Captured on ${formatCurrentDateTime()}`,
                type: "GENERAL",
                followupdate: null,
                date_enable: "NO",
                status: "ACTIVE"
            };
            
            await addFollowupMutation.mutateAsync(capturedFollowupData);

        }

        if (response?.message === "Success" && data.comments) {
            const followupData = {
                applicant_id: response?.appData?.id,
                assign: store?.user?.id,
                comments: data.comments,
                type: "GENERAL",
                followupdate: null,
                date_enable: "NO",
                status: "ACTIVE"
            };

           
            await addFollowupMutation.mutateAsync(followupData);
            setIsLoading(false);
            toast.success('Walk-in saved and assigned successfully');
            navigate('/walkinform');
        }else if(response?.message === "Success"){
            setIsLoading(false);
            toast.success('Walk-in saved and assigned successfully');
            navigate('/walkinform');
        }
        
       
             
    } catch (error) {
        setIsLoading(false);
        toast.error(error?.message || 'Something went wrong');
    }
    };

    const AllStaffList = authQueries.useWalkinStaffList(
        (response) => {
            setAllStaff(response)
            let dataArray = []
            for (let datas of response) {

                const dataValues = {
                    label: datas?.user?.userdetail?.firstname + ' ' + datas?.user?.userdetail?.lastname,
                    value: datas?.user?.id
                }

                dataArray = [...dataArray, dataValues]
            }

            setAllStaff(dataArray)
        }
    );
    const officeList = authQueries.useOfficeMutationList(
        (response) => {
            setOfficeData(response)
            let dataArray = []
            for (let datas of response?.data?.items) {
                const dataValues = {
                    label: datas?.name,
                    value: datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }

            setOfficeData(dataArray)
        }
    );

    const prospectList = authQueries.useProspectList(
        (response) => {
            setProspects(response)
            let dataArray = []
            for (let datas of response?.data?.items) {

                const dataValues = {
                    label: datas?.name,
                    value: datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }

            setProspects(dataArray)
        }
    );


    const masterList = authQueries.useWalkinMasterList(
        (response) => {
            setUsers(response)
            console.log("response", response);
            let dataArray = []
            let visaArray = []
            for (let datas of response?.data?.user) {

                const dataValues = {
                    label: datas?.userdetail?.firstname + ' ' + datas?.userdetail?.lastname,
                    value: datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }

            for (let datas of response?.data?.visatype) {

                const dataValues = {
                    label: datas?.name,
                    value: datas?.id
                }

                visaArray = [...visaArray, dataValues]
            }

            setVisaData(visaArray)
            setUsers(dataArray)
        }
    );

    const handleOfficeSelect = async () => {
        console.log("visa,", visa);
        await  AllStaffList.mutateAsync({office});
        await  masterList.mutateAsync({ visa:'', office, refferal: '' });
    };

    const AddWalkin = authQueries.useWalkincreate(
       async (response) => {
            if (response?.message === "Success") {

            } else {
                setIsLoading(false);
                toast.error(response?.message);
            }
        }
    );




      const addFollowupMutation = authQueries.useAppointClientAdd(
        (response) => {
        }
    );

    return (
        <div>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/walkinform">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Add Existing Walk-In</li>
                    </ol>
                </nav>
            </div>
            <div className="add_clients" style={{ borderBottom: '0px' }}>
                <div className="flex items-center gap-[4px] mb-4">
                    <i className="followup-icon">
                        <img src={require('../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                    </i>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <span className="text-[16px] font-medium">Existing Walk-In</span>
                        </div>
                    </div>
                </div>
                <div id="accordion" className="add_clients_accordion_form">
                    <div className="card" id="appointments_wrpr">
                        <div className="card-header flex justify-between">
                            <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                            Existing Walk-In
                            </a>
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className='col-md-12'>
                                    <form onSubmit={existingHandleSubmit(onExistingSubmit)}>
                                        <div className="row">

                                            <div className="col-md-4">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">First Name</label>
                                                        <input className="form-control" {...existingRegister("fname")} type="text" name='fname' />
                                                        <div className="text-danger">{existingErrors.fname?.message}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Middle Name</label>
                                                        <input className="form-control" {...existingRegister("mname")} type="text" name='mname' />
                                                        <div className="text-danger">{existingErrors.mname?.message}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Last Name</label>
                                                        <input className="form-control" {...existingRegister("lname")} type="text" name='lname' />
                                                        <div className="text-danger">{existingErrors.lname?.message}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Email</label>
                                                        <input className="form-control"  {...existingRegister("email")} type="email" name='email' />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Purpose of Visit<em className="mandatory">*</em></label>
                                                        <select  {...existingRegister("purpose")}
                                                             onChange={handlePurposeChange} className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" name='name'>
                                                            <option value="">Select Purpose</option>
                                                            <option value="Study">Study Enquiry</option>
                                                            <option value="Migration">Migration Enquiry</option>
                                                            <option value="Delegate">Delegate Visit</option>
                                                            <option value="Interview">Interview</option>
                                                            <option value="Holidays"> M&G Holidays</option>
                                                            <option value="Others">Others</option>
                                                        </select>
                                                        <div className="text-danger">{existingErrors.purpose?.message}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            {showMigrationType && (
                                                <div className="col-md-6">
                                                    <div className='form-group'>
                                                        <div id='parent' className='form-group'>
                                                            <label htmlFor="">Migration Type</label>
                                                            <select 
                                                                {...existingRegister("migration_type")} 
                                                                onChange={(e) => {
                                                                    setMigrationType(e.target.value);
                                                                    existingSetValue("migration_type", e.target.value);
                                                                }}
                                                                className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0"
                                                            >
                                                                <option value="">Select Migration Type</option>
                                                                <option value="Canada">Canada Migration</option>
                                                                <option value="Australia">Australia Migration</option>
                                                            </select>
                                                            <div className="text-danger">{existingErrors.migration_type?.message}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {showHolidayType && (
                                                <div className="col-md-6">
                                                    <div className='form-group'>
                                                        <div id='parent' className='form-group'>
                                                            <label htmlFor="">Holiday Type</label>
                                                            <select 
                                                                {...existingRegister("holiday_type")} 
                                                                onChange={(e) => {
                                                                    setHolidayType(e.target.value);
                                                                    existingSetValue("holiday_type", e.target.value);
                                                                }}
                                                                className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0"
                                                            >
                                                                <option value="">Select Holidays Type</option>
                                                                <option value="Ticketing">Ticketing</option>
                                                                <option value="Visas">Visas</option>
                                                                <option value="Insurance">Travel Insurance</option>
                                                                <option value="Booking">Group tour booking</option>
                                                            </select>
                                                            <div className="text-danger">{existingErrors.holiday_type?.message}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Phone<em className="mandatory">*</em></label>
                                                        <Controller
                                                            name='phone'
                                                            control={existingControl}
                                                            className="form-control"
                                                            render={({ field: { onChange, value } }) => (
                                                                <div

                                                                onPaste={(e) => {
                                                                    // e.preventDefault();
                                                                    let pastedText = e.clipboardData.getData("text").replace(/\D/g, "");
                                                                    if(!pastedText.startsWith("91")){
                                                                        pastedText = "91" + pastedText; // Ensure it starts with +91
                                                                    }
                                                                    setCode("in");
                                                                    setMobile(pastedText);
                                                                    setMobileValid("");
                                                                    onChange(pastedText);
                                                                }}
                                                                >
                                                                <PhoneInput
                                                                    enableSearch={true}
                                                                    country={code}
                                                                    value={value}
                                                                    onChange={(value, country, e, formattedValue) => {
                                                                        const digitsOnly = formattedValue.replace(/\D/g, ''); // Remove non-digit characters
                                                                        const countryCodeLength = country.countryCode.length; // Get length of country code
                                                                        const extractedPhoneNumber = digitsOnly.slice(countryCodeLength);
                                                                        setCode(country.countryCode)
                                                                        const parsedPhoneNumber = parsePhoneNumberFromString(`+${value}`);
                                                                        if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
                                                                            onChange(value)
                                                                            setMobile(extractedPhoneNumber);
                                                                            setMobileValid('')
                                                                        }
                                                                    }}
                                                                />
                                                                </div>
                                                            )}
                                                            rules={{ required: true }}
                                                        />
                                                        <div className="text-danger">{existingErrors.phone?.message}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Landline number</label>
                                                        <Controller
                                                            name='landline_no'
                                                            control={existingControl}
                                                            className="form-control"
                                                            render={({ field: { onChange, value } }) => (
                                                                <div

                                                                onPaste={(e) => {
                                                                    // e.preventDefault();
                                                                    let pastedText = e.clipboardData.getData("text").replace(/\D/g, "");
                                                                    if(!pastedText.startsWith("91")){
                                                                        pastedText = "91" + pastedText; // Ensure it starts with +91
                                                                    }
                                                                    setCodeLandline("in");
                                                                    setMobile(pastedText);
                                                                    setMobileValid("");
                                                                    onChange(pastedText);
                                                                }}
                                                                >
                                                                <PhoneInput 
                                                                    disabled={existingData?.registered_status === 'YES' && (existingData?.pros_status!==2 || existingData?.pros_status!==3 || existingData?.pros_status!==24) }
                                                                    enableSearch={true}
                                                                    country={codeLandline}
                                                                    value={value}
                                                                    onChange={(value, country, e, formattedValue) => {
                                                                        const digitsOnly = formattedValue.replace(/\D/g, ''); // Remove non-digit characters
                                                                        const countryCodeLength = country.countryCode.length; // Get length of country code
                                                                        const extractedPhoneNumber = digitsOnly.slice(countryCodeLength);
                                                                        setCodeLandline(country.countryCode)
                                                                        const parsedPhoneNumber = parsePhoneNumberFromString(`+${value}`);
                                                                        if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
                                                                            onChange(value)
                                                                            setMobile(extractedPhoneNumber);
                                                                            setMobileValid('')
                                                                        }
                                                                    }}
                                                                />
                                                                </div>
                                                            )}
                                                            rules={{ required: true }}
                                                        />
                                                        <div className="text-danger">{existingErrors.landline_no?.message}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'> 
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Original Branch of Student</label>
                                                        <input className="form-control" disabled value={existingData?.office?.name} type="text" name='branch' />
                                                    </div>
                                                </div>   
                                            </div>
                                            {(existingData?.pros_status===2 || existingData?.pros_status===3 || existingData?.pros_status===24) &&
                                             <div className="col-md-6">
                                                 <div className='form-group'>
                                                     <div id='parent' className='form-group'>
                                                         <label htmlFor="">Visa Type</label>
                                                         <AutoCompleteChilds
                                                             dis={disableVisaType} 
                                                             control={existingControl}
                                                             data={visaData}
                                                             name={"visa_type_id"}
                                                             value={visa}
                                                             setVisaId={setVisaId}
                                                             error={existingErrors.visa_type_id}
                                                         />
                                                         {disableVisaType && (
                                                             <small className="text-muted">
                                                                 Visa type not applicable for selected purpose
                                                             </small>
                                                         )}
                                                     </div>
                                                 </div>
                                            </div>                                        
                                            }           
                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Student walked in Branch</label>
                                                        <AutoCompleteAntdOffice
                                                            dis={existingData?.registered_status === 'YES' && (existingData?.pros_status!==2 && existingData?.pros_status!==3 && existingData?.pros_status!==24) }
                                                            setOffice={setOffice}
                                                            control={existingControl}
                                                            data={officeData}
                                                            name={"office_id"}
                                                            value={store?.user?.office[0]?.oid}
                                                            error={existingErrors.office_id}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            {existingData?.assignUser?.CounselorAssign &&(
                                            <div className='col-md-6'>
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">File Owned By Counselor</label>
                                                        <input className="form-control" disabled value={existingData?.assignUser?.CounselorAssign?.userdetail?.firstname+' '+existingData?.assignUser?.CounselorAssign?.userdetail?.lastname} type="text" name='branch' />
                                                    </div>
                                                </div>            
                                            </div>
                                            )}

                                            {existingData?.assignUser?.AdmissionAssign &&(
                                            <div className='col-md-6'>
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Admission Staff</label>
                                                        <input className="form-control" disabled value={existingData?.assignUser?.AdmissionAssign?.userdetail?.firstname+' '+existingData?.assignUser?.AdmissionAssign?.userdetail?.lastname} type="text" name='branch' />
                                                    </div>
                                                </div>            
                                            </div>
                                            )}

                                            {existingData?.assignUser?.DocumentAssign &&(
                                            <div className='col-md-6'>
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Document Staff</label>
                                                        <input className="form-control" disabled value={existingData?.assignUser?.DocumentAssign?.userdetail?.firstname+' '+existingData?.assignUser?.DocumentAssign?.userdetail?.lastname} type="text" name='branch' />
                                                    </div>
                                                </div>            
                                            </div>
                                            )}

                                            {existingData?.assignUser?.ProgramAssign &&(
                                            <div className='col-md-6'>
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Program Staff</label>
                                                        <input className="form-control" disabled value={existingData?.assignUser?.ProgramAssign?.userdetail?.firstname+' '+existingData?.assignUser?.ProgramAssign?.userdetail?.lastname} type="text" name='branch' />
                                                    </div>
                                                </div>            
                                            </div>
                                            )}

                                            {existingData?.assignUser?.VisaAssign &&(
                                            <div className='col-md-6'>
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Visa  Staff</label>
                                                        <input className="form-control" disabled value={existingData?.assignUser?.VisaAssign?.userdetail?.firstname+' '+existingData?.assignUser?.VisaAssign?.userdetail?.lastname} type="text" name='branch' />
                                                    </div>
                                                </div>            
                                            </div>
                                            )}

                                            {existingData?.assignUser?.FeeAssign &&(
                                            <div className='col-md-6'>
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Fee  Coordinator</label>
                                                        <input className="form-control" disabled value={existingData?.assignUser?.FeeAssign?.userdetail?.firstname+' '+existingData?.assignUser?.FeeAssign?.userdetail?.lastname} type="text" name='branch' />
                                                    </div>
                                                </div>            
                                            </div>
                                            )}

                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Walk –in Attended By</label>
                                                        <select  className="form-control" name='attendedby'  {...existingRegister("attendedby")}>
                                                            <option value=''>Select Staff</option>
                                                            {
                                                                allStaff?.map((item, index) => (
                                                                    <option key={index} value={item?.value}>
                                                                        {item?.label}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        {existingData?.pros_status===2 || existingData?.pros_status===24?
                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Assign to Counselor<em className="mandatory">*</em></label>
                                                        <select className="form-control" name='assign'  {...existingRegister("assign")}>
                                                            <option value=''>Select Counselor</option>
                                                            {
                                                                users?.map((item, index) => (
                                                                    <option key={index} value={item?.value}>
                                                                        {item?.label}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                        <div className="text-danger">{existingErrors.assign?.message}</div>
                                                    </div>
                                                </div>
                                            </div>:null
                                        }                   

                                            <div className='col-md-12'>
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Comments {purpose==='Others'?<em className="mandatory">*</em>:''}</label>
                                                        <textarea className="form-control" {...existingRegister("comments")} type="text" name='comments' />
                                                        <div className="text-danger">{existingErrors.comments?.message}</div>
                                                    </div>
                                                </div>            
                                            </div>

                                        </div>

                                        <div className="flex justify-end">
                                            {
                                                isLoading ? (
                                                    <button
                                                        type="submit"
                                                        style={{ backgroundColor: '#323a51' }}
                                                        className="px-4 py-2 text-white rounded-md hover:opacity-90"
                                                    >
                                                        Loading...
                                                    </button>
                                                ) : (
                                                    <>
                                                    <button
                                                        type="submit"
                                                        style={{ backgroundColor: '#323a51' }}
                                                        className="px-4 py-2 text-white rounded-md hover:opacity-90"
                                                    >
                                                        Save
                                                    </button>

                                                    <button
                                                        onClick={() => navigate('/walkinform')}
                                                        type="button"
                                                        style={{ backgroundColor: '#323a51' }}
                                                        className="px-4 py-2 text-white rounded-md hover:opacity-90 ml-2"
                                                    >
                                                        Cancel
                                                    </button>

                                                    </>
                                                )
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}
