import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox } from '@nextui-org/react';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as yup from 'yup';
import authQueries from '../../../queries/authQueries';
import { userStore } from "../../../store/userStore";
import { AutoCompleteAntd } from '../../Clients/AddClients/AutoComplete';


const validationSchema = yup.object({

    country: yup
      .string('⚠ Enter Country Name')
      .required('⚠ Country Name is required'),
    intake: yup
      .string('⚠ Enter Intake Name')
      .required('⚠ Intake Name is required')
      .min(2,'⚠ Intake Name must be more than 2 characters '),
    visacategory: yup
      .string('⚠ Enter Visa Category')
      .required('⚠ Visa Category is required')
      .min(2,'⚠ Visa Category must be more than 2 characters '),
    visa: yup
      .string('⚠ Enter Visa Type')
      .required('⚠ Visa Type is required'),
    date: yup
      .date()
      .required('⚠ Date is required'),
    status: yup
      .string('⚠ Enter your status')
      .required('⚠ Status is required'),
  });
  

const EditIntake = () => {

    const navigate = useNavigate();
    const master = userStore();

    const [countryResult, setCountryResult] = useState([])
    const [visaResult, setVisaResult] = useState([])
    const [errName, setErrName] = useState('')
    const [today, setToday] = useState(new Date())
    const [dates, setDates] = useState('')
    const [cdata, setCdata] = useState(null)
    const [vdata, setVdata] = useState(null)

    const [select, setSelect] = useState("INACTIVE")


    const onChange = (date, dateString) => {
        console.log(date, dateString);
      };

    useEffect(()=>{
        startData();
        // document.getElementById('arrival_date').setAttribute('min', new Date().toISOString().split('T')[0])
    },[])

    const startData = async() => {
       await CountryList.mutateAsync({search: '', status: '', page:0, size:500});
       await VisaList.mutateAsync({search: '', status: '', page:0, size:500});
       await IntakeData.mutateAsync(master.intake);
    }


    const IntakeData = authQueries.useIntakeMutationView(
        (response) => {
            console.log(response)
            if(response?.data){
                setSelect(response?.data.active_status)
                let datas = ({
                    visacategory: response?.data.visa_category,
                    visa: response?.data.visa_type_id,
                    country: response?.data.country_id,
                    intake: response?.data.intake,
                    date: response?.data.intake_date,
                    status:response?.data.status,
                })
                setCdata(response?.data.country_id)
                setVdata(response?.data.visa_type_id)
                reset({ ...datas });
                setDates(moment(response?.data.date).format('DD-MM-YYYY'));
            }
        }   
    );


    const { register,reset, handleSubmit, control, formState:{ errors } } = useForm({

        resolver: yupResolver(validationSchema)
    
      });


    const CountryList = authQueries.useCountryMutationList(
            (response) => {
                setCountryResult(response)

                let dataArray = []
                for(let datas of response?.data?.items){
                    const dataValues = {
                        label:datas?.name,
                        value:datas?.id
                    }
    
                    dataArray = [...dataArray, dataValues]
                }
                setCountryResult(dataArray)

            }
        );

    const VisaList = authQueries.useVisaTypeMutationList(
        (response) => {
            setVisaResult(response)

            let dataArray = []
                for(let datas of response?.data?.items){
                    const dataValues = {
                        label:datas?.description,
                        value:datas?.id
                    }
    
                    dataArray = [...dataArray, dataValues]
                }

                setVisaResult(dataArray)

        }
    );

    const editIntake = authQueries.useIntakeMutationEdit(
        (response) => {
            if(response?.message === "Success"){
                navigate('/intake')
            }else if(response?.error[0].intake){
                setErrName("⚠ "+response?.error[0].intake)
            }
        }   
    );
       

    const onSubmit = (data) => {

        const datavalues = {
            id: master.intake,
            visa_category: data.visacategory,
            visa_type_id: data.visa,
            country_id: data.country,
            intake: data.intake,
            active_status: select,
            intake_date: data.date,
            status: data.status,
        };
    
        // e.preventDefault()
    
        editIntake.mutateAsync(datavalues);    
    
      }
    

    return ( 

        <div>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/intake">Intakes</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Edit Intake</li>
                </ol>
            </nav>

            <div className="add_clients">

                <div className="card">
                    <div className="card-header">
                        Edit Intake
                    </div>
                    <div id="travel_history" className="collapse show" data-parent="#accordion">

                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                <label htmlFor="">Visa Category</label>
                                                <select  className="form-control" name='visacategory'  {...register("visacategory")}>
                                                    <option value=''>Select</option>
                                                    <option value='STUDENT'>STUDENT</option>
                                                    <option value='IMMIGRATION'>IMMIGRATION</option>
                                                    
                                                </select>
                                                <p className='error'>{errors.visacategory?.message}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                            <label htmlFor="">Visa Type :</label>

                                                <AutoCompleteAntd
                                                    control={control} 
                                                    data={visaResult} 
                                                    name={"visa"} 
                                                    value={vdata}
                                                />
                                                <div className='error-msg'>{errors.visa?.message}</div>
                                            </div>
                                        </div>
                                    </div>  

                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                            <label htmlFor="">Country :</label>
                                                <AutoCompleteAntd
                                                    control={control} 
                                                    data={countryResult} 
                                                    name={"country"} 
                                                    value={cdata}
                                                    // error={errors.country}
                                                />
                                                <div className='error-msg'>{errors.country?.message}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                <label htmlFor="">Intake</label>
                                                <input className="form-control"  {...register("intake")} type="text" name='intake' />
                                                <p className='error'>{errors.intake?.message} {errName}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className='form-group'>
                                            <div id='parent' className='form-group'>
                                                <label htmlFor="">Date</label>
                                                <input id='' type='date' {...register("date")}  name='date' placeholder={dates} className="form-control" />
                                                <p className='error'>{errors.date?.message}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="">Status</label>
                                        <select className="form-control" name='status'  {...register("status")}>
                                            <option value=''>Select</option>
                                            <option value="ACTIVE">ACTIVE</option>
                                            <option value="INACTIVE">INACTIVE</option>
                                        </select>
                                        <p className='error'>{errors.status?.message}</p>
                                    </div>

                                    <div className="col-md-6">
                                    <Checkbox color="success" isSelected={select === "ACTIVE"?true: false} onChange={(e)=>{setSelect(e?"ACTIVE":"INACTIVE")}} >
                                        <span style={{fontWeight:300, fontSize:"16px"}}>Select as default</span>
                                    </Checkbox>
                                    </div>

                                </div>
                                
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <button type='submit' className="btn btn-default btn-save">Update</button>
                                    </div>
                                </div>
                            </form>                            
                        </div>
                    </div>
                </div>
            </div>

            

        </div>

     );
}
 
export default EditIntake;