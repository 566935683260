import { Loading, Modal } from '@nextui-org/react'
import { decode } from 'html-entities'
import moment from 'moment'
import React, { Fragment, useState, useEffect } from 'react'
import { userStore } from '../../store/userStore'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import authQueries from '../../queries/authQueries'
import { useForm } from 'react-hook-form'
import { useApi } from './ApiProvider'
import LoaderCommon from './LoaderCommon'
import ModalHeaderText from './ModalHeaderText'
import { InactiveStatus } from '../../Data/InactiveStatus'
import Swal from 'sweetalert2'

const ProspectStatusList = ({item, readonly, defaultStatus}) => {

    const [open, setOpen] = useState(false)
    const [status, setStatus] = useState([])
    const [loader, setLoader] = useState(true)
    const [applicant, setApplicant] = useState({})

    const store = userStore()

    const handleOpen =()=>{
        setOpen(true)
        setLoader(true)
        setApplicant(item)
        listStatus.mutateAsync({role: store?.user?.rid, visa: item?.visa_type_id, showstatus: item?.registered_status})
    }

    const handleClose =()=>{
        setOpen(false)
    }

    const listStatus = authQueries.useStatusOfProspectsRoleList(
		(response) => {
			setStatus(response)
            setLoader(false)
		}
	);

  return (
    <Fragment>
        <div onClick={handleOpen} style={{color:'#00f'}} className="btn-fee cursor-pointer">
            {defaultStatus ? defaultStatus : item?.status_of_prospect?.name}
        </div>
        <Modal
        open={open}
        closeButton
        onClose={handleClose}
        className="followup-modal"
        centered
        blur
        animationDuration={1000}
        overlayId="modal-overlay"
        width='90%'
        scroll
        >
            <Modal.Header>
                <ModalHeaderText label="Prospect Status" />
            </Modal.Header>
            <Modal.Body>
                {
                    loader ?
                    <LoaderCommon />:
                    <StatusCreate item={applicant} status={status} readonly={readonly} handleClose={handleClose} />
                }
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    </Fragment>
  )
}

export default ProspectStatusList


const StatusCreate =({item, status, readonly, handleClose})=> {

	const [statusResult, setStatusResult] = useState([])
	const [statusId, setStatusId] = useState(null)
	const [reason, setReason] = useState(null)

    const store = userStore()

    const { setApiDataAndNotify } = useApi();

	useEffect(()=> {
	  listProspect.mutateAsync(item?.id)
	}, [])
  
	// const validationSchema = yup.object({
    //     comment: yup
    //         .string('⚠ Please enter comments')
    //         .required('⚠ comments is required'),
    //     prospect: yup
    //         .string('⚠ Please Select Prospect Status')
    //         .required('⚠ Prospect Status is required')
	// });

    const validationSchema = yup.object().shape({
        prospect: yup
            .string()
            .required('⚠ Prospect Status is required'),
        comment: yup.string(),
        nir_id: yup.string(),
    }).test(
        'comment-or-nir_id',
        function (values) {
            const { prospect, comment, nir_id } = values;
    
            if (prospect === '2') {
                if (!comment && !nir_id) {
                    return this.createError({
                        path: 'comment',
                        message: '⚠ Comment or not interested reason is required',
                    });
                }
            } else {
                if (!comment) {
                    return this.createError({
                        path: 'comment',
                        message: '⚠ Comment is required',
                    });
                }
            }
            return true;
        }
    );
    
	const onSubmit = (data) => {

        if(parseInt(data?.prospect) === 18){
            Swal.fire({
                text: "Are you sure you want to update the status to Visa Refusal",
                showCancelButton: true,
                confirmButtonText: "Confirm",
                confirmButtonColor: 'blue',
                cancelButtonColor: 'gray'
              }).then((result) => {
                if(result.isConfirmed) {
                    const datavalues = {
                        applicant_id: item?.id,
                        prospects_id: data.prospect,
                        visa: item?.visa_type_id,
                        comments: data.comment,
                        status: "ACTIVE"
                    }
                    addProspect.mutateAsync(datavalues);
                    Swal.fire("Saved!", "", "success");
                } else if (result.isDenied) {
                    Swal.fire("Changes are not saved", "", "info");
                    return
                }
            })
        }else{
            const datavalues = {
                applicant_id: item?.id,
                prospects_id: data.prospect,
                nir_id: data.nir_id,
                visa: item?.visa_type_id,
                comments: data.comment,
                status: "ACTIVE"
            }
            addProspect.mutateAsync(datavalues);
        }
	}
  
	const addProspect = authQueries.useProStatusAdd(
	  (response) => {
		const dataValues = {
		  prospect: '',
		  comment: '',
          nir_id: ''
		}
		reset(dataValues)
        setApiDataAndNotify(response)
        const inactive = InactiveStatus?.find(item => item === parseInt(response?.data?.sid))
        if(inactive){
            handleClose()
            Swal.fire({
                text: "Prospect status changed to inactive",
                icon: "success",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Close",
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then((result) => {
                if (result.isConfirmed) {
                    handleClose()
                }
            })
        }
		listProspect.mutateAsync(item?.id)
        
	  }
	);
  
	const { register , reset, handleSubmit, formState:{ errors }, setValue} = useForm({
		resolver: yupResolver(validationSchema)
	});
  
	const listProspect = authQueries.useProStatusList(
	  (response) => {
		setStatusResult(response)
	  }
	);

    const offerAccepted = () => {
        const accepted = item?.intended_programs?.find(item => item?.sid === 7)
        if(accepted){
            return true
        }else{
            return false
        }
    }

    const accepted = offerAccepted()

  
	return(
        <div className="row">
            {!readonly &&
                <div className="col-md-3">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex flex-col gap-3'>
                            <div className="flex flex-col gap-1">
                                <div className='text-[12px] h-4 font-[500]'>Status</div>
                                    <select
                                    disabled={store?.user?.role === "VISASTAFF" && !accepted && item?.visatype?.program === "YES" ? true: false}
                                    {...register('prospect', { 
                                        onChange: (e) => {
                                            setValue('nir_id', '')
                                            setStatusId(e.target.value)
                                        }
                                    })} 
                                    name="prospect" 
                                    className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" 
                                    >
                                    <option value="">Select Status</option>
                                        {status?.data?.map((pros, index) => {
                                            if(pros?.id === 2 && item?.registered_status === "YES" && (store?.user?.role === "COUNSELOR" || store?.user?.role === "BMANAGER")){
                                                return
                                            }
                                            return(
                                                <option value={pros?.id} key={index}>{decode(pros?.name)}</option>
                                            )
                                        })}
                                    </select>
                                <div style={{color:'#f00', fontSize:"13px"}}>{errors?.prospect?.message}</div>
                            </div>
                            {
                                Number(statusId) === 2 &&
                                <div className="flex flex-col gap-1">
                                    <div className='text-[12px] h-4 font-[500]'>Not interested reason</div>
                                        <select
                                        {...register('nir_id')} 
                                        name="nir_id"
                                        className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" 
                                        >
                                        <option value="">Select not interested reason</option>
                                            {status?.notinterested?.map((pros, index) => {
                                                return(
                                                    <option value={pros?.id} key={index}>{decode(pros?.name)}</option>
                                                )
                                            })}
                                        </select>
                                    <div style={{color:'#f00', fontSize:"13px"}}>{errors?.prospect?.message}</div>
                                </div>
                            }
                            <div className="flex flex-col gap-1">
                                <div className='text-[12px] h-4 font-[500]'>Comment</div>
                                <textarea
                                disabled={store?.user?.role === "VISASTAFF" && !accepted && item?.visatype?.program === "YES" ? true: false}
                                {...register('comment')}
                                name="comment" 
                                className="bg-white border border-solid border-gray-300 rounded-md w-full min-h-[140px] p-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" 
                                rows="5"
                                ></textarea>
                                <div style={{color:'#f00', fontSize:"13px"}}>{errors?.comment?.message}</div>
                            </div>
                            {
                                store?.user?.role === "VISASTAFF" ?
                                <div>
                                    {
                                        (!accepted && item?.visatype?.program === "YES") ?
                                        <button type="button" disabled className='btn-customized-large opacity-70 cursor-not-allowed'>Submit</button>:
                                        <button type="submit" className="btn-customized-large">Submit</button>
                                    }
                                </div>:
                                <div>
                                    {
                                        (store?.user?.role === 'COUNSELOR' && item?.assignUser?.counselor !== store?.user?.id) ?
                                        <button type="button" disabled className='btn-customized-large opacity-70 cursor-not-allowed'>Submit</button>:
                                        <button type="submit" disabled={addProspect.isLoading} className="btn-customized-large gap-2">
                                            {
                                                addProspect.isLoading &&
                                                <Loading size='xs' color="white" />
                                            }
                                            {addProspect.isLoading ? "Loading..." : "Submit"}
                                        </button>
                                    }
                                </div>
                            }
                            {
                                (store?.user?.role === "VISASTAFF" && !accepted && item?.visatype?.program === "YES") && <div className="text-[11px] text-justify italic text-[red]">You must have at least one offer accepted in your application to change the visa status. Please ensure you have accepted an offer before proceeding.</div>
                            }
                        </div>
                    </form>
                </div>
            }
                                
            <div className={readonly ? "col-md-12":"col-md-9"}>
                <div className="table-wrpr">
                    <div className="table-section">
                        <div className="row">
                            <div className='col-md-12'>
                                <table className="table prospect-table">
                                    <thead>
                                        <tr>
                                            <th width="90">Date</th>
                                            <th width="300">Status</th>
                                            <th width="400">Comment</th>
                                            <th width="90">Commented By</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {
                                        statusResult.data?.map((item, index) => (
                                            <tr className="ui-sortable-handle">
                                                <td width="100">{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                                                <td width="130">{decode(item?.status_of_prospect?.name)}</td>
                                                <td width="40">
                                                    {item?.not_interested_reason ? 
                                                        <div>{item?.not_interested_reason?.name} {item?.comments ? " - " + item?.comments : "" } </div>
                                                        : item?.comments
                                                    }
                                                </td>
                                                <td width="120">{item?.createdBy?.userdetail?.firstname} {item?.createdBy?.userdetail?.lastname}</td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	)
}
