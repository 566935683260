import { useMutation } from "react-query";
import referralClientService from "../services/referralClientService";

const useCreateReferralClient = (onData) => {
  return useMutation(referralClientService.createReferralClient, {
    onSuccess: (data) => onData(data),
  })
}

const useGetReferralClient = (onData) => {
  return useMutation(referralClientService.getReferralClient, {
    onSuccess: (data) => onData(data),
  })
}

const referralClientQueries = {
  useCreateReferralClient,
  useGetReferralClient
}

export default referralClientQueries