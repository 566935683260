import React, { useEffect, useState, Fragment } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import { Modal, useModal, Button, Text } from "@nextui-org/react";
import CountryWise from "./CountryWise";
import Uniquecountrywise from "./Uniquecountrywise";
import { userStore } from '../../../store/userStore'
import { getCurrentFinancialYear, getNextFinancialYear, getPreviousFinancialYear } from '../../Common/financialYear';
import moment from 'moment';

const CountrywiseReport =()=>{

    function getCurrentFinancialYearDates() {
        const today = new Date();
        const currentMonth = today.getMonth(); // 0-11 where 0 is January
        const currentYear = today.getFullYear();
    
        
        let financialYearStart, financialYearEnd;
        
        // If current month is January to March (0-2), we're in the previous year's financial year
        if (currentMonth >= 0 && currentMonth <= 2) {
            financialYearStart = new Date(currentYear - 1, 3, 1); // April 1st of previous year
            financialYearEnd = new Date(currentYear, 2, 31);      // March 31st of current year
        } else {
            financialYearStart = new Date(currentYear, 3, 1);     // April 1st of current year
            financialYearEnd = new Date(currentYear + 1, 2, 31);  // March 31st of next year
        }
    
        function formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
    
        return {
            startDate: formatDate(financialYearStart),
            endDate: formatDate(financialYearEnd)
        };
    }
    
    function formatDate(date) {
        var year = date.getFullYear();
        var month = ('0' + (date.getMonth() + 1)).slice(-2); 
        var day = ('0' + date.getDate()).slice(-2);
        return year + '-' + month + '-' + day;
    }
    
    var financialYearDates = getCurrentFinancialYearDates();

    const [fromDate, setfromDate] = useState(financialYearDates.startDate);
    const [toDate, settoDate] = useState(financialYearDates.endDate);

    const [fromDateapi, setfromDateapi] = useState( financialYearDates.startDate);
    const [toDateapi, settoDateapi] = useState(financialYearDates.endDate);
    const user = userStore();
    const role = user?.user?.rid;
    const currentFinancialYear = getCurrentFinancialYear()
    const nextFinancialYear = getNextFinancialYear()
    const previousFinancialYear = getPreviousFinancialYear()
     const [dateRange, setDateRange] = useState("CURRENT")

     function getFinancialYear(type = "CURRENT") {
        // Validate the type parameter
        const validTypes = ["CURRENT", "PREVIOUS", "FUTURE"];
        if (!validTypes.includes(type)) {
          throw new Error("Type must be one of: CURRENT, PREVIOUS, FUTURE");
        }
        
        // Get current date
        const today = new Date();
        const currentMonth = today.getMonth(); // 0-11 (0 = January, 11 = December)
        const currentYear = today.getFullYear();
        
        // Determine offset based on type parameter
        let yearOffset = 0;
        if (type === "PREVIOUS") {
          yearOffset = -1;
        } else if (type === "FUTURE") {
          yearOffset = 1;
        }
        
        // Determine financial year start year
        // If we're in January, February, or March (months 0-2), 
        // then the financial year started in the previous calendar year
        let financialYearStartYear = currentMonth < 3 ? currentYear - 1 : currentYear;
        
        // Apply the offset
        financialYearStartYear += yearOffset;
        const financialYearEndYear = financialYearStartYear + 1;
        
        // Format the financial year string
        return { startDate:`${financialYearStartYear}-04-01`, endDate:`${financialYearEndYear}-03-31}` };
      }
      

      
      
    
    const handlefromDate = (event) => {
        const value = event.target.value;
        setfromDate(value);
    };

    const handletoDate = (event) => {
        const value = event.target.value;
        settoDate(value);
    };

    const handleSearch = () => {
        if(dateRange === "PREVIOUS"){
            setfromDateapi(getFinancialYear("PREVIOUS").startDate)
            settoDateapi(getFinancialYear("PREVIOUS").endDate)
        }else if(dateRange === "CURRENT"){
        
            setfromDateapi(getFinancialYear("CURRENT").startDate)
            settoDateapi(getFinancialYear("CURRENT").endDate)

        }else if(dateRange === "FUTURE"){
            
            setfromDateapi(getFinancialYear("FUTURE").startDate)
            settoDateapi(getFinancialYear("FUTURE").endDate)
        }else{
            setfromDateapi(fromDate)
            settoDateapi(toDate)
        }
        
        console.log("fromDateapi",fromDateapi,"toDateapi",toDateapi)
      };
    
    
      const selectDate=(e)=>{
        setDateRange(e.target.value)
    }


    return(
        <div>
            <div className="add_clients">			
                    <div className="row">
                            
                        <div className="col-md-12">
                            <hr className="progress-hr" />
                        </div>
                    </div>
                    

                    <div id="accordion" className="add_clients_accordion_form" style={{borderBottom:'none'}}>
                        <div  className="card" id="appointments_wrpr_employee">

                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className="row ">
                                    <div className="col-md-12">
                                        <div className="table-list-search-wrpr">
                                            <div className="row">
                                                        <div className='grid grid-cols-12 gap-4 max-[1446px]:grid-cols-10 max-[1094px]:grid-cols-8 max-[949px]:grid-cols-6 max-[640px]:grid-cols-4 max-[483px]:grid-cols-2 !w-full mb-4'>
                                                            <div className='col-span-3'>
                                                                <label>Select Date</label>
                                                                <select value={dateRange} className='form-control form-control-sm !w-full !min-h-[38px]' onChange={selectDate}>
                                                                    <option value="PREVIOUS">Previous Financial Year ({previousFinancialYear})</option>
                                                                    <option value="CURRENT">Current Financial Year ({currentFinancialYear})</option>
                                                                    <option value="FUTURE">Next Financial Year ({nextFinancialYear})</option>
                                                                    <option value="CUSTOM">Custom Date</option>
                                                                </select>
                                                            </div>
                                                            {dateRange === "CUSTOM" &&
                                                                <Fragment>
                                                                    <div className='col-span-2'>
                                                                        <label>From Date</label>
                                                                        <input 
                                                                        max={moment(toDate).format("YYYY-MM-DD")} 
                                                                        value={moment(fromDate).format('YYYY-MM-DD')} 
                                                                        type='date' onChange={handlefromDate}
                                                                        className='form-control form-control-sm !w-full !min-h-[38px]' 
                                                                        />
                                                                    </div>
                                                                    <div className='col-span-2'>
                                                                        <label>To Date</label>
                                                                        <input 
                                                                        min={moment(fromDate).format("YYYY-MM-DD")} 
                                                                        value={moment(toDate).format('YYYY-MM-DD')} 
                                                                        type='date' onChange={handletoDate}
                                                                        className='form-control form-control-sm !w-full !min-h-[38px]' 
                                                                        />
                                                                    </div>
                                                                </Fragment>
                                                            }
                                                            <div className='col-span-1 flex flex-col'>
                                                                <label>&nbsp;</label>
                                                                <button onClick={handleSearch} className='mt-[3px] rounded-md text-[12px] !w-full !min-h-[38px] bg-[#323a51] border-none text-white'>
                                                                    Search
                                                                </button>
                                                            </div>
                                                        </div>
                                                {/* <div className="col-md-6">
                                                </div>
                                                <div className="col-md-6 text-right">
                                                    <div className="table-search-filter-wrpr">
                                                        <div style={{display: "-webkit-inline-box" }} className="table-search-wrpr">
                                                            <label style={{marginRight:7}}>From</label>
                                                            <input style={{minWidth: '100px'}} value={fromDate} onChange={handlefromDate}  className="form-control form-control-sm" type="date" placeholder="From" />
                                                            <label style={{marginRight:7}}>To</label>
                                                            <input style={{minWidth: '100px'}} value={toDate} onChange={handletoDate}  className="form-control form-control-sm" type="date" placeholder="TO" />
                                                            <button style={{background: '#323a51'}} onClick={handleSearch}  className="btn btn-info">Search</button>
                                                        </div>
                                                    </div>
                                                </div> */}
                                        </div>                           
                                           
                                        <CountryWise fromDate={fromDateapi} toDate={toDateapi} />
                                       
                                        <Uniquecountrywise fromDate={fromDateapi} toDate={toDateapi}/>
                                    

                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
    </div>
    ) 
}
export default CountrywiseReport;