import { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import authQueries from '../../queries/authQueries';
import LoaderCommon from '../Common/LoaderCommon';
import moment from 'moment';
import { Empty } from 'antd';
import RouteConstants from '../../Constants/RouteConstans';
import { Modal, Button, List } from "antd";
import { FolderOpen } from 'lucide-react';
import PhoneInput from 'react-phone-input-2'
import parsePhoneNumberFromString, { parsePhoneNumber } from 'libphonenumber-js'
import { userStore } from '../../store/userStore';
const validationSchema = yup.object({
    mobile: yup.string().required('⚠ Phone Number is required'),
});


export default function WalkinForm() {
    const appStore = userStore()
    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [followupComments, setFollowupComments] = useState([]);
    const [code, setCode] = useState('in');
    const [mobile, setMobile] = useState('');
    const [mobileValid, setMobileValid] = useState('')
    const [visaid, setVisaId] = useState(appStore?.appAllData?.applicant?.visa_type_id);
    const [mobileExist, setMobileExist] = useState('')
    
    const navigate = useNavigate();

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });


    let followupCmments = [];

    const getApplicant = authQueries.useWalkinClientSearch(
        (res) => {
            setSearch(true)
            if (res?.message === "success") {
                setResult(res?.existingUser)
                res?.existingUser?.followups?.map((item) => {
                    followupCmments.push(item.followupComments[0]?.comments)
                }
                )
                setFollowupComments(followupCmments)
            } else {
                setResult({})
            }
        }
    )


    const onSearch = (data) => {
        getApplicant.mutateAsync(data);
        console.log("data", followupCmments);
    }



    const followupComment =  (data) => {
        data?.followups?.map((item) => {
            followupCmments.push(item.followupComments[0]?.comments)
        }
        )
        setFollowupComments(followupCmments);
        setIsModalVisible(true)
    }


    return (
        <div>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Walk-In</li>
                    </ol>
                </nav>
            </div>
            <div className="add_clients" style={{ borderBottom: '0px' }}>
                <div className="flex items-center gap-[4px] mb-4">
                    <i className="followup-icon">
                        <img src={require('../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                    </i>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <span className="text-[16px] font-medium">Walk-In</span>
                        </div>
                    </div>
                </div>
                <div id="accordion" className="add_clients_accordion_form">
                    <div className="card" id="appointments_wrpr">
                        <div className="card-header flex justify-between">
                            <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                                Search for Existing User
                            </a>
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className='col-md-12'>

                                    <form onSubmit={handleSubmit(onSearch)}>
                                        <div className='grid grid-cols-12 gap-4'>
                                            <div className="col-span-2 flex flex-col">
                                                <div className='text-[12px] h-[25px] '>Contact No</div>
                                                {/* <Controller
                                                            name='mobile'
                                                            control={control}
                                                            className="bg-white border border-solid border-gray-300 rounded-md w-full !min-h-[40px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0"
                                                            render={({ field: { onChange, value } }) => (
                                                                <div
                                                                    onPaste={(e) => {
                                                                        e.preventDefault();
                                                                        let pastedText = e.clipboardData.getData("text").replace(/\D/g, "");
                                                                        const countryCodeLength = code.length;
                                                                        const extractedPhoneNumber = pastedText.startsWith(code)
                                                                            ? pastedText.slice(countryCodeLength)
                                                                            : pastedText;

                                                                        setMobile(extractedPhoneNumber);
                                                                        setMobileValid("");
                                                                        onChange(extractedPhoneNumber);
                                                                    }}
                                                                >
                                                                <PhoneInput
                                                                    style={{ width: '500px' }}
                                                                    enableSearch={true}
                                                                    country={code}
                                                                    value={value}
                                                                    onChange={(value, country, e, formattedValue) => {
                                                                        const digitsOnly = formattedValue.replace(/\D/g, ''); // Remove non-digit characters
                                                                        const countryCodeLength = country.countryCode.length; // Get length of country code
                                                                        const extractedPhoneNumber = digitsOnly.slice(countryCodeLength);
                                                                        setCode(country.countryCode)
                                                                        const parsedPhoneNumber = parsePhoneNumberFromString(`+${value}`);
                                                                        if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
                                                                            onChange(value)    
                                                                            setMobile(extractedPhoneNumber);
                                                                            setMobileValid('')
                                                                            //mobileValidation({number:extractedPhoneNumber, visa:visaid, type:"MOBILE"});
                                                                        } else {
                                                                            setMobileExist('')
                                                                            setMobileValid("Provide valid Mobile no")
                                                                        }
                                                                    }}
                                                                />
                                                                </div>
                                                            )}
                                                            rules={{ required: true }}
                                                        /> */}

                                                <input
                                                    style={{ width: '500px' }}
                                                    {...register("mobile")}
                                                    onChange={(e) => {
                                                        setMobile(e.target.value)
                                                        setMobileValid('')
                                                    }
                                                    }
                                                    placeholder='Enter Contact No'
                                                    name="mobile"
                                                    type="text"
                                                    className="bg-white border border-solid border-gray-300 rounded-md w-full !min-h-[40px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0"
                                                />
                                                <div className="text-danger">{errors.mobile?.message}</div>
                                            </div>

                                            <div className='col-span-2 flex flex-col'  style={{marginLeft:'290px'}}>
                                                <div className='h-[25px]'></div>
                                                <button type="submit" className="btn-customized-large w-[120px] h-[38px] rounded-md">
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    </form>


                                    <div style={{ marginTop: '20px' }}>
                                        {getApplicant.isLoading ?
                                            <LoaderCommon /> :
                                            Object.keys(result).length > 0 ?
                                                <div className="table-wrpr">
                                                    <div className="table-section">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <table className="table prospect-table mb-0">
                                                                    <thead>
                                                                        <tr className='text-[13px]'>


                                                                            <th>Office</th>
                                                                            <th>Date Created</th>
                                                                            <th>Applicant Name</th>
                                                                            <th>Contact Number</th>
                                                                            <th>Visa Type</th>
                                                                            <th>Intake</th>
                                                                            <th>Counselor</th>
                                                                            <th>Reg. Date</th>
                                                                            <th>Prospect Status</th>
                                                                            <th>Comments</th>
                                                                            <th>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="ui-sortable">

                                                                        <Fragment>

                                                                        {result?.map((item, index) => (
                                                                            <tr className="ui-sortable-handle text-[11px]">


                                                                                <td>{item?.office?.name}</td>
                                                                                <td>{moment(result?.createdAt).format('DD-MM-YYYY')}</td>
                                                                                <td>
                                                                                    {item?.applicant_name + ' ' + item?.middlename + ' ' + item?.lastname}
                                                                                </td>
                                                                                <td>
                                                                                    <div className="email-mobile flex flex-col">
                                                                                        <div>{item?.mobile_no}</div>
                                                                                        <div>{item?.landline_no}</div>
                                                                                        <div>{item?.whatsapp_no}</div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{item?.visatype?.description}</td>
                                                                                <td>{item?.intake?.intake}</td>
                                                                                <td className='font-bold text-[14px]'>
                                                                                    {item?.assignUser?.CounselorAssign?.userdetail?.firstname + ' ' + item?.assignUser?.CounselorAssign?.userdetail?.lastname
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        item.registered_date ?
                                                                                            <span className='registered'>
                                                                                                {item.registered_date && moment(item?.registered_date).format('DD-MM-YYYY')}
                                                                                            </span> :
                                                                                            "-"
                                                                                    }
                                                                                </td>
                                                                                <td className={item?.pros_status === 2 ? "text-red-500" : "text-black-500"}>
                                                                                    {item?.status_of_prospect?.name}
                                                                                </td>
                                                                                <td style={{ cursor: 'pointer' }} onClick={() => followupComment(item)} className='text-blue-700 text-sm underline underline-offset-1'>
                                                                                    view last 5 comments
                                                                                </td>
                                                                                <td style={{ cursor: 'pointer' }}
                                                                                    onClick={() => {
                                                                                        navigate(RouteConstants.EXISTNGWALKIN, {
                                                                                            state: {
                                                                                                data: item
                                                                                            }
                                                                                        })
                                                                                    }} className='text-blue-700 text-sm'>

                                                                                    <button type="submit" className="btn-customized-large w-[120px] h-[38px] rounded-md">
                                                                                        Add Walk-In
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        </Fragment>
                                                                   
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div className="min-h-[400px] w-full flex items-center justify-center p-8">
                                                    <div className="max-w-md w-full text-center space-y-6">
                                                        {/* Icon Container with subtle animation */}
                                                        <div className="flex justify-center">
                                                            <div className="rounded-full bg-blue-50 p-6 animate-pulse">
                                                                <FolderOpen className="w-12 h-12 text-blue-500" />
                                                            </div>
                                                        </div>

                                                        {/* Main Text */}
                                                        <div className="space-y-2">
                                                            <h3 className="text-lg font-semibold text-gray-900">
                                                                No Clients Found
                                                            </h3>
                                                            <p className="text-sm text-gray-500">
                                                                {search && Object.keys(result).length === 0
                                                                    ? "No results match your search criteria."
                                                                    : "Start by Searching for Existing User"}
                                                            </p>
                                                        </div>

                                                        {/* Action Button - Only shown when search yields no results */}
                                                        {search && Object.keys(result).length === 0 && (
                                                            <div className="flex justify-center">
                                                                <button
                                                                    onClick={() => navigate(RouteConstants.FRESHWALKIN, { 
                                                                        state: { mobile }
                                                                      })}
                                                                    className="inline-flex items-center px-4 py-2 bg-blue-600 hover:bg-blue-700 
                    text-white text-sm font-medium rounded-lg transition-colors 
                    duration-200 ease-in-out shadow-sm hover:shadow-md"
                                                                >
                                                                    <span className="mr-2">+</span>
                                                                    Add Fresh Walk-In
                                                                </button>
                                                            </div>
                                                        )}

                                                        {/* Bottom Border Decoration */}
                                                        <div className="pt-6">
                                                            <div className="w-24 h-1 bg-blue-100 rounded-full mx-auto"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </div>



                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <FollowUpCommentsModal
                visible={isModalVisible}
                onClose={() => setIsModalVisible(false)}
                comments={followupComments}
            />
        </div>
    )
}


const FollowUpCommentsModal = ({ visible, onClose, comments }) => {
    // Ensure comments is an array and filter out falsy values
    const safeComments = Array.isArray(comments) ? comments.filter(comment => comment != null) : [];

    return (
        <Modal
            title="Latest 5 Follow-Up Comments"
            open={visible}
            onCancel={onClose}
            footer={[
                <Button key="close" onClick={onClose}>
                    Close
                </Button>,
            ]}
        >
            {safeComments.length > 0 ? (
                <List
                    bordered
                    dataSource={safeComments}
                    renderItem={(item, index) => (
                        <List.Item key={index}>
                            <strong>Comment {index + 1}:</strong> {item}
                        </List.Item>
                    )}
                />
            ) : (
                <p>No follow-up comments available.</p>
            )}
        </Modal>
    );
};