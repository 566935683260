import { Checkbox } from '@nextui-org/react'
import React, { Fragment } from 'react'
import { Controller } from 'react-hook-form'

const SelectCheckbox = ({item}) => {
  return (
    <Fragment>
      <div className="text-[12px] h-4 font-[500]" />
      <Checkbox
      className="bg-white w-full h-10 text-[12px]"
      isSelected={item?.state === "YES" ? true : false}
      onChange={(e) => {
          item?.setState(e ? "YES" : "NO");
      }}>
          <div className='text-[12px] text-poppins'>
              {item?.label}
          </div>
      </Checkbox>
    </Fragment>
  )
}

export default SelectCheckbox