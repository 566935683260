import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";
import App from './App';
import './assets/css/bootstrap.float.label.min.css'
import './assets/css/bootstrap.min.css'
import './assets/css/circliful.css'
import './assets/css/swiper.min.css'
import './assets/css/font.css'
import './assets/css/login.css'
import './assets/css/style.css'
import './index.css'
import './assets/css/developer.css'
import './assets/css/loader.css'
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import reportWebVitals from "./reportWebVitals";
import { ApiProvider } from './components/Common/ApiProvider';

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <ApiProvider>
        <App />
      </ApiProvider>
    </QueryClientProvider>
  </BrowserRouter>
);

reportWebVitals();


