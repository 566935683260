import { decode } from 'html-entities';
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import authQueries from '../../../queries/authQueries';
import { userStore } from '../../../store/userStore';
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from 'yup';
import {  Badge, Checkbox, message as messages, Popconfirm, Select } from 'antd';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, Loading } from '@nextui-org/react';
import AutoCompleteInput from '../../Common/AutoCompleteInput'
import InputSelectForm from '../../Common/InputSelectForm'
import { generateValidationSchema } from '../../Common/validationSchema';
import AssignModal from '../../Common/AssignModal';
import EditIntentedProgramModal from './EditIntentedProgramModal';
import toast from 'react-hot-toast';
import { useApi } from '../../Common/ApiProvider';
import { socket } from '../../../socket/socket';
import Swal from 'sweetalert2';

const IntendedCollege = () => {

    const appStore = userStore();

    const { apiData } = useApi()

    const [result, setResult] = useState(appStore?.appAllData?.intended_programs);
    const [checkID, setCheckID] = useState([]);
    const [intakeResult, setIntakeResult] = useState([]);
    const [programResult, setProgramResult] = useState([]);
    const [collegeResult, setCollegeResult] = useState([]);
    const [college, setCollege] = useState('')
    const [program, setProgram] = useState("")

    useEffect(()=>{
        setResult(appStore?.appAllData?.intended_programs)
    },[apiData])

    useEffect(() => {
        collgeListing.mutateAsync(appStore?.appAllData?.applicant?.visa_type_id);
    }, [appStore?.appAllData?.applicant?.visa_type_id])

    useEffect(()=> {
        inTakeListing.mutateAsync(appStore?.appAllData?.applicant?.visa_type_id);
    }, [appStore?.appAllData?.applicant?.visa_type_id])

    const programListing = authQueries.useProgramMutationList(
        (response) => {
            if(response?.data?.items){
                let dataArray = []
                for(let datas of response?.data?.items){
                    const dataValues = {
                        label:datas?.name,
                        value:datas?.id,
                        link: datas?.programlink
                    }

                    dataArray = [...dataArray, dataValues]
                }
                setProgramResult(dataArray)
            }else{
                setProgramResult([])
            }
        }
    );

    const inTakeListing = authQueries.useIntakeVisaMutationList(
        (response) => {
            let dataArray = []
            for(let datas of response?.data){
                const dataValues = {
                    name:datas?.intake,
                    id:datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }
            setIntakeResult(dataArray)
        }
    );

    const collgeListing = authQueries.useCollegeCountryMutationList(
        (response) => {
            let dataArray = []
            for(let datas of response?.data){
                const dataValues = {
                    name:datas?.name,
                    id:datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }
            setCollegeResult(dataArray)
        }
    );

    const campusList = authQueries.useCampusMutationList(
        (response) => {
        }
    )

    useEffect(()=> {
        setValue('program', null)
        setValue('campus', null)
        if(college){
            programListing.mutateAsync({size:50000, search: '', status: '', college:college});
            campusList.mutateAsync({id:college})
        }
    },[college])

    const inputFields = [
        {
            name: "intake",
            placeholder: "Select or Enter Intake",
            label: "Intake",
            size: "2",
            type: AutoCompleteInput,
            required: true,
            options: intakeResult,
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}
        },
        {
            name: "college",
            placeholder: "Select or Enter College/University",
            label: "College/University",
            size: "2",
            type: AutoCompleteInput,
            required: true,
            setState: setCollege,
            options: collegeResult,
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}
        },
        {
            name: "campus",
            placeholder: "Select or Enter Campus",
            label: "Campus",
            size: "2",
            type: AutoCompleteInput,
            required: false,
            options: campusList?.data?.data?.items,
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}
        },
        {
            name: "program",
            placeholder: "Select or Enter Program",
            label: "Program",
            size: "3",
            type: AutoCompleteInput,
            setState: setProgram,
            required: true,
            options: programListing?.data?.data?.items,
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}
        },
        {
            name: "placement",
            label: "Placement",
            size: "2",
            type: InputSelectForm,
            required: false,
            options: [{name:"WITH PLACEMENT"}, {name:"WITHOUT PLACEMENT"}],
            optionProps:{name: "name", name2: "", value: "name", value2: "", advanced: false}
        },
    ]

    const { register, reset,control, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    });

    const addIntentProgram = authQueries.useIntProgramAdd(
        (response) => {
            setValue('placement', '')
            setPgm({name: "", link: ""})
            reset()
            toast.success("Successfully created a program")
            viewAllApplicationData.mutateAsync(appStore.appid)
        }
    );

    const onSubmit = (data) => {

        const datavalues = {
            applicant_id: appStore.appid,
            intended_college_or_university: data.college,
            campus: data.campus ? data.campus : null,
            intended_program: data.program,
            intake: data.intake,
            placement: data.placement ? data.placement : null,
            status: "ACTIVE"
        }

        addIntentProgram.mutateAsync(datavalues);
    }

    const deleteIntent = authQueries.useIntProgramDelete(
        (response) => {
            toast.success("successfully deleted")
            viewAllApplicationData.mutateAsync(appStore.appid)
        }
    );

    const deleteIntentedData = (event, id) => {
        event.preventDefault();
        deleteIntent.mutateAsync(id)
    }

    const handleDragEnd = (e) => {
        if (!e.destination) return;
        let tempData = Array.from(result);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        setResult(tempData);
        const arry = []
        tempData.forEach(element => {
            arry.push(element.id)
        })
        dragOrder.mutateAsync({id: arry})
    };

    const dragOrder = authQueries.useIntProgramDragOrderMutation(
        (response)=> {
            viewAllApplicationData.mutateAsync(appStore.appid)
        }
    )

    const handleMail =()=>{
        mailSend.mutateAsync({
            email: appStore?.appAllData?.applicant?.email,
            appid: appStore.appid,
            ids: checkID
        })
    }

    const mailSend = authQueries.useMailSendProgramMutation(
        (res)=> {
            // toast.success("Email send successfully")
            Swal.fire({
                icon: 'success',
                title: 'Confirmation sent successfully',
                text: 'Email send successfully',
                showConfirmButton: false,
                timer: 5000
            })
            setCheckID([])
            viewAllApplicationData.mutateAsync(appStore.appid)
        }
    )

    const handleWhatsapp =()=>{
        confirmWhatsapp.mutateAsync({
            to: appStore?.appAllData?.applicant?.whatsapp_no,
            id: appStore.appid,
            programs: checkID,
            counselor: {
                name: appStore?.user?.name,
                image: appStore?.user?.image,
                phone: appStore?.user?.phone
            }
        })
    }

    useEffect(()=>{
        socket.on('whatsappConfimation', (message) => {
            viewAllApplicationData.mutateAsync(appStore.appid)
        });
    },[])

    const confirmWhatsapp = authQueries.useWhatsappConfirmation(
        (res)=> {
            console.log(res?.response?.data?.message)
            if(res?.message === "success"){
                // toast.success("Sending whatsapp successfully")
                Swal.fire({
                    icon: 'success',
                    title: 'Confirmation sent successfully',
                    text: 'Sending whatsapp successfully',
                    showConfirmButton: false,
                    timer: 5000
                })
                setCheckID([])
                viewAllApplicationData.mutateAsync(appStore.appid)
            }else{
                toast.error(res?.response?.data?.message)
            }
        }
    )

    const handleCheckAll = (e) => {
        let selected = []
        const {checked} = e.target
        if(checked){
            result?.map(id => {
                selected.push(id.id)
            })
        }
        setCheckID(selected)
    };
  
    const handleCheck = (e) => {
        const {checked, value} = e.target
        const selectID = parseInt(value)
        setCheckID([...checkID, selectID])
        if(!checked){
            setCheckID(checkID.filter((item) => item !== selectID));
        }
    };
  

    const children = (id) => {
        return (
            <Checkbox
                checked={checkID.find(number => number === id) ? true: false}  
                onChange={handleCheck} 
                value={id} 
            />
        );
    }

    const assignToProgram = async ()=> {
        const datavalues = {
            applicant_id: appStore.appid,
            prospects_id: 5,
            comments: "counselor assign to program coordinator",
            status: "ACTIVE"
        }
        await addProspect.mutateAsync(datavalues)
        await viewAllApplicationData.mutateAsync(appStore.appid)
    }


    const addProspect = authQueries.useProStatusAdd(
        (response) => {
        }
    );

    const viewAllApplicationData = authQueries.useApplicationMutationViewAll(
        async (response) => {
            if (response.data) {
                await appStore.setApplicationAllData(response.data)
                setResult(response?.data?.intended_programs)
            }
        }
    )

    const [pgm, setPgm] = useState({name:"", link: ""})

    useEffect(()=>{
        const pro = programResult?.find(element => element.value === program)
        if(pro){
            setPgm({name: pro?.label, link: pro?.link})
            return 
        }
        setPgm({name:"", link: ""})
        return
    }, [program])

    const user = useMemo(() => {
        const assignedUsers = appStore?.appAllData?.applicant?.assign_to_counsellors;
        const user = assignedUsers?.find(element => element?.role_id === 6);
        const userDetails = user?.user?.userdetail;
      
        if (userDetails) {
          return {
            name: `${userDetails.firstname} ${userDetails.lastname}`,
            image: userDetails.image,
          };
        } else {
          return null;
        }
    }, [appStore?.appAllData?.applicant?.assign_to_counsellors]);

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <a className="collapsed card-link" data-toggle="collapse" href="#intended_college_program">
                        Intended College /Program
                    </a>
                    <div className={result?.length > 0 ? "tab-info fields-completed" :  "tab-info fields-notcompleted" }>
                        {
                            user &&
                            <div className='flex items-center pr-5 gap-3'>
                                <img src={user?.image || require('../../../assets/images/profile-avatar.jpg')} className='w-[30px] h-[30px] rounded-full' alt="" />
                                <span className='text-[14px] text-black font-semibold'>{user?.name}</span>
                            </div>
                        }
                        <span className={result?.length > 0 ? 'icon-checkmark1' : 'icon-cancel1'}></span>
                    </div>
                </div>

                <div id="intended_college_program" className="collapse " data-parent="#accordion">
                    <div className="card-body">

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="grid grid-cols-12  gap-x-4 max-[1384px]:grid-cols-9 max-[1029px]:grid-cols-6 max-[676px]:grid-cols-4">
                               
                                {
                                    inputFields?.map((item, index)=>(
                                        <div key={index} className={`col-span-${item?.size} flex flex-col gap-2 intented-program`}>
                                            <item.type item={item} register={register} errors={errors} control={control} options={item?.options} optionProps={item?.optionProps}/>
                                        </div>
                                    ))
                                }

                                <div className="col-span-1 flex flex-col gap-2">
                                    <div className="text-[12px] h-4 font-[500]"></div>
                                    <button type='submit' className='btn-customized-large !min-h-[40px]'>Create</button>
                                </div>

                            </div>
                        </form>

                        <div>
                            {pgm?.link &&
                                <div className='flex flex-wrap text-[12px] gap-4 bg-green-200 py-2 px-4 rounded-lg'>
                                    <div>{pgm?.name}</div>
                                    <a className='text-blue-500' href={pgm?.link}>{pgm?.link}</a>
                                </div>
                            }
                        </div>

                        {result?.length > 0 &&
                            <div className='my-2'>
                                {
                                    (appStore?.user?.role === "COUNSELOR" || appStore?.user?.role === "MANAGER" || appStore?.user?.role === "REGISTRATION") &&
                                    <Fragment>
                                        {appStore?.appid &&
                                            <div className='flex justify-end gap-2 w-full'>
                                                {appStore?.user?.role === "COUNSELOR" &&
                                                    <Fragment>
                                                        {mailSend.isLoading ? 
                                                        <button className='btn-customized-large text-white text-[12px] font-normal w-[230px] min-w-[240px] h-[40px] rounded-md'>
                                                            <Loading color='white' size='xs' />
                                                        </button>:
                                                        <button
                                                        disabled={checkID.length === 0}
                                                        onClick={handleMail}
                                                        className='btn-customized-large text-white text-[12px] font-normal w-[240px] min-w-[230px] h-[40px] rounded-md'
                                                        style={{opacity: checkID.length === 0 ? '0.7' : '1'}}
                                                        >
                                                            Student confirmation via email
                                                        </button>}
                                                    </Fragment>
                                                }
                                                {appStore?.user?.role === "COUNSELOR" &&
                                                    <Fragment>
                                                        {confirmWhatsapp.isLoading ? 
                                                        <button className='btn-customized-large text-white text-[12px] font-normal w-[230px] min-w-[240px] h-[40px] rounded-md'>
                                                            <Loading color='white' size='xs' />
                                                        </button>:
                                                        <button
                                                        disabled={checkID.length === 0}
                                                        onClick={handleWhatsapp} 
                                                        className='btn-customized-large text-white text-[12px] font-normal w-[240px] min-w-[230px] h-[40px] rounded-md'
                                                        style={{opacity: checkID.length === 0 ? '0.7' : '1'}}
                                                        >
                                                            Student confirmation via whatsapp
                                                        </button>}
                                                    </Fragment>
                                                }
                                                <div className='h-[40px] min-w-[200px]'>
                                                    <AssignModal 
                                                    item={appStore?.appAllData?.applicant}
                                                    label="Assign Program Coordinator"
                                                    roles={[6]} 
                                                    selectedRole={6}
                                                    height={"40px"}
                                                    fontSize="12px"
                                                    assignToProgram={assignToProgram}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </Fragment>
                                }
                            </div>
                        }

                        <div className='row'>
                            <div className="col-md-12 mt-2">
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    <div className="table-wrpr">
                                        <div className="table-section">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <table className="table prospect-table">
                                                        <thead>
                                                            <tr>
                                                                <th width="2%">
                                                                    <Checkbox
                                                                    checked={result?.length === checkID?.length && result?.length !== 0}
                                                                    indeterminate={result?.length !== checkID?.length && checkID?.length > 0}
                                                                    onChange={handleCheckAll} 
                                                                    />
                                                                </th>
                                                                <th>Priority</th>
                                                                <th>Intake</th>
                                                                <th>Intended program</th>
                                                                <th>College/University</th>
                                                                <th>Campus</th>
                                                                <th>Placement</th>
                                                                <th>Prgm.Coordinator Status</th>
                                                                <th>Student Status</th>
                                                                <th>&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <Droppable droppableId="droppable-1">
                                                            {(provider) => (
                                                            <tbody
                                                                className="text-capitalize"
                                                                ref={provider.innerRef}
                                                                {...provider.droppableProps}
                                                            >
                                                                {result?.map((item, index) => (
                                                                <Draggable
                                                                    key={`items${item.id}`}
                                                                    draggableId={`items${item.id}`}
                                                                    index={index}
                                                                >
                                                                    {(provider) => (
                                                                    <Fragment>
                                                                        <tr {...provider.draggableProps} ref={provider.innerRef}>
                                                                            <td>{children(item?.id)}</td>
                                                                            <td className="numbercol" {...provider.dragHandleProps}>{index + 1}</td>
                                                                            <td>{item?.intake?.intake}</td>
                                                                            <td>
                                                                                <div style={{display:'flex', gap: 10, alignItems:'center'}}>
                                                                                    {
                                                                                        item.sending === 0 && 
                                                                                        <span 
                                                                                        style={{height: '15px', display:'flex',color:'#fff', alignItems:'center'}} 
                                                                                        className="badge bg-success">New</span>
                                                                                    }
                                                                                    <a href={item?.program?.programlink} target='_blank'>{item?.program?.name}</a>
                                                                                </div>
                                                                            </td>
                                                                            <td>{decode(item?.college?.name)}</td>
                                                                            <td>{item?.campus?.name}</td>
                                                                            <td>{item?.placement}</td>
                                                                            <td>{item?.status}</td>
                                                                            <td>{item?.app_status}</td>
                                                                            <td className="flex gap-1 justify-end">
                                                                                {
                                                                                    (appStore?.user?.role === "ADMISSIONSTAFF" || appStore?.user?.role === "SENIORPROGRAMMANAGER" || (appStore?.user?.role === "COUNSELOR" && appStore?.appAllData?.applicant?.registered_status !== "YES")) &&
                                                                                    <EditIntentedProgramModal 
                                                                                    item={item}
                                                                                    intakeResult={intakeResult}
                                                                                    collegeResult={collegeResult}
                                                                                    programListing={programListing}
                                                                                    campusList={campusList}
                                                                                    college={college}
                                                                                    setCollege={setCollege}
                                                                                    setProgram={setProgram}
                                                                                    program={program}
                                                                                    viewAllApplicationData={viewAllApplicationData}
                                                                                    />
                                                                                }
                                                                                {
                                                                                    item.status !== "CONFIRMED" ?
                                                                                    <Popconfirm
                                                                                        placement="left"
                                                                                        title="Are you sure to delete this program?"
                                                                                        onConfirm={(event) => {deleteIntentedData(event, item.id)}}
                                                                                        okText="Yes"
                                                                                        cancelText="No"
                                                                                    >
                                                                                        <button type='button' className="bg-[#dc3545] h-[30px] w-[60px] text-white rounded-[6px] text-[12px]">Delete</button>
                                                                                    </Popconfirm>:
                                                                                    <button style={{opacity:'0.5'}} disabled className="bg-[#dc3545] h-[30px] w-[60px] text-white rounded-[6px] text-[12px]">Delete</button>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    </Fragment>
                                                                    )}
                                                                </Draggable>
                                                                ))}
                                                                {provider.placeholder}
                                                            </tbody>
                                                            )}
                                                        </Droppable>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DragDropContext>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default IntendedCollege