import { decode } from 'html-entities';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import authQueries from '../../../queries/authQueries';
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import * as yup from 'yup';
import { userStore } from '../../../store/userStore';
import { message } from 'antd';
import { useApi } from '../../Common/ApiProvider';
import toast from 'react-hot-toast'
import InputUseForm from '../../Common/InputUseForm';
import AutoCompleteInput from '../../Common/AutoCompleteInput';
import { generateValidationSchema } from '../../Common/validationSchema';
import MobileUseForm from '../../Common/MobileUseForm';
import referralClientQueries from '../../../queries/referralClientQueries';
import RouteConstants from '../../../Constants/RouteConstans';
import { useNavigate } from 'react-router-dom';

const ReferralClients = () =>  {

    const appStore = userStore();
    const { setApiDataAndNotify, apiData } = useApi()

    const navigate = useNavigate()
    
    const [visa, setVisa] = useState(null)
    
    const masterData = authQueries.useApplicantFormMastersList(
      (res)=>{
      }
    )

    useEffect(() => {
      masterData.mutateAsync({visa})
    }, [visa]);

    const inputFields = [
        {
            name: 'applicant_name',
            label: 'Name',
            type: 'text',
            placeholder: 'Name',
            required: true
        },
        {
            name: 'email',
            label: 'Email',
            type: 'email',
            placeholder: 'Email',
            required: true
        },
        {
            name: 'mobile_no',
            label: 'Mobile',
            type: 'mobile',
            placeholder: 'Mobile',
            required: true
        },
        {
            name: 'visa_type_id',
            label: 'Visa Type',
            type: 'select',
            placeholder: 'Visa Type',
            required: true,
            state: visa,
            setState: setVisa,
            options: masterData?.data?.data?.visatype || [],
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}
        },
        {
            name: 'intake_id',
            label: 'Intake',
            type: 'select',
            placeholder: 'Intake',
            required: true,
            options: masterData?.data?.data?.intake || [],
            optionProps:{name: "name", name2: "", value: "id", value2: "", advanced: false}            
        }
    ]
    
    const { register, control, handleSubmit, reset, setValue, trigger, setError, formState:{ errors }, watch } = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    });

    const createReferralClient = referralClientQueries.useCreateReferralClient(
        (res)=>{
            if(res?.success){
                setApiDataAndNotify(new Date())
                toast.success("Successfully created")
                setValue('applicant_name', "")
                setValue('email', "")
                setValue('mobile_no', "+91")
                setValue('visa_type_id', "")
                setValue('intake_id', "")
                viewAllApplicationData.mutateAsync(appStore.appid)
              }else{
                toast.error(res?.message)
            }
        }
    )

    const onSubmit = async(data) => {
      if(data?.mobile_no && data?.mobile_no?.length < 7){
        setError('mobile_no', { type: 'manual', message: 'Provide valid Mobile no' });
        return
      }
      data.referred_by = appStore?.appid
      createReferralClient.mutateAsync(data)
    }

    const viewAllApplicationData = authQueries.useApplicationMutationViewAll(
      async (response) => {
        if (response.data) {
          await appStore.setApplicationAllData(response.data)
        }
      }
    )

    const handleClick = async (item) => {
      await appStore.setApplication("")
      window.open(`${RouteConstants.CLIENTADD}`, '_blank')
    }

  return (
    <div className="card">
      <div className="card-header">
        <a className="collapsed card-link" data-toggle="collapse" href="#referral_clients">
            Referral Leads
        </a>
      </div>
      <div id="referral_clients" className="collapse" data-parent="#accordion">
        <div className="card-body">
          <div className="mb-3">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-12 gap-4">
                    {
                        inputFields.map((item, index) => (
                            <div className="col-span-3 flex flex-col gap-2" key={index}>
                                {
                                    item?.type === "select" ?
                                    <AutoCompleteInput
                                    item={item}
                                    register={register}
                                    control={control}
                                    errors={errors}
                                    options={item?.options}
                                    optionProps={item?.optionProps}
                                    />:
                                    item?.type === "mobile" ?
                                    <MobileUseForm
                                    item={item}
                                    control={control}
                                    register={register}
                                    errors={errors}
                                    setError={setError}
                                    />
                                    :
                                    (item?.type === "text" || item?.type === "number" || item?.type === "email") &&
                                    <InputUseForm
                                    item={item}
                                    control={control}
                                    register={register}
                                    errors={errors}
                                    />
                                }
                            </div>
                        ))
                    }
                    <div className="col-span-3">
                        <label className="block text-[14px] font-medium text-gray-700">&nbsp;</label>
                        <button disabled={createReferralClient.isLoading} type="submit" className="custom-color text-[14px] text-white h-[40px]  px-4 py-2 rounded-md">
                            {createReferralClient.isLoading ? 'Creating...' : 'Create'}
                        </button>
                    </div>
                </div>

            </form>
          </div>
          {
            appStore?.appAllData?.referral_clients?.length > 0 &&
            <div className='row'>
              <div className="col-md-12 mt-2">
                <div className="table-wrpr">
                  <div className="table-section">
                    <table className="table prospect-table !text-[14px]">
                      <thead>
                        <tr>
                          <th width="2%">#</th>
                          <th>Intake</th>
                          <th>Visa Type</th>
                          <th>Name</th>
                          <th>Mobile</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody className="text-capitalize">
                        {appStore?.appAllData?.referral_clients?.map((item, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item?.intake?.intake}</td>
                            <td>{item?.visatype?.description}</td>
                            <td>{item?.applicant_name}</td>
                            <td>{item?.mobile_no}</td>
                            <td>{item?.email}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default ReferralClients