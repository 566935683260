import { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import authQueries from '../../queries/authQueries';
import LoaderCommon from '../Common/LoaderCommon';
import moment from 'moment';
import { Empty } from 'antd';
import { userStore } from '../../store/userStore';
import toast from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2'
import parsePhoneNumberFromString from 'libphonenumber-js';
import { AutoCompleteAntdOffice, AutoCompleteChilds } from '../Clients/AddClients/AutoComplete';


const fresherValidationSchema = yup.object({
    fname: yup.string().required('⚠ First Name is required'),
    phone: yup.string().required('⚠ Phone Number is required'),
    purpose: yup.string().required('⚠ purpose is required'),
    migration_type: yup.string().when('purpose', {
        is: 'Migration',
        then: () => yup.string().required('⚠ Migration Type is required'),
    }),
    holiday_type: yup.string().when('purpose', {
        is: 'Holidays',
        then: () => yup.string().required('⚠ Holiday Type is required'),
    }),
    comments: yup.string().when('purpose', {
        is: 'Others',
        then: () => yup.string().required('⚠ Comment is required'),
    }),
    assign: yup.string().required('⚠ Assign to Counselor is required'),
});


export default function FreshWalkinForm() {

    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [office, setOffice] = useState('');
    const [prosStatus, setProsStatus] = useState('');
    const [officeData, setOfficeData] = useState([]);
    const [users, setUsers] = useState([]);
    const [formData, setFormData] = useState({});
    const [allStaff, setAllStaff] = useState([]);
    const [prospects, setProspects] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [code, setCode] = useState('in');
    const [codeLandline, setCodeLandline] = useState('in')
    const [mobile, setMobile] = useState('');
    const [mobileValid, setMobileValid] = useState('')
    const [visa, setVisaId] = useState('');
    const [visaData, setVisaData] = useState([]);
    const [comments, setComments] = useState('');
    const store = userStore()
    const navigate = useNavigate();
    const location = useLocation();
    const mobileNumber = location.state?.mobile;
    const [migrationType, setMigrationType] = useState('');
    const [showMigrationType, setShowMigrationType] = useState(false);
    const [disableVisaType, setDisableVisaType] = useState(false);

    const [holidayType, setHolidayType] = useState('');
    const [showHolidayType, setShowHolidayType] = useState(false);
    const [purpose, setPurpose] = useState('');

    useEffect(() => {
        if(mobileNumber.length===10)
        {
            fresherSetValue('phone', '91'+mobileNumber)
        }else{
            fresherSetValue('phone', mobileNumber)
        }
        
        officeList.mutateAsync({ search: '', status: '', page: 0, size: 500 });
        AllStaffList.mutateAsync();
        prospectList.mutateAsync({ search: '', status: '', page: 0, size: 500 });
        setOffice(store?.user?.office[0]?.oid)
    }, [])

    useEffect(() => {
        handleOfficeSelect();
    }, [office, visa])


    const {
        register: fresherRegister,
        handleSubmit: fresherHandleSubmit,
        setValue: fresherSetValue,
        control: fresherControl,
        formState: { errors: fresherErrors }
    } = useForm({
        resolver: yupResolver(fresherValidationSchema)
    });


    const handlePurposeChange = (e) => {
        console.log("e", e.target.value)
        const value = e.target.value;
        fresherSetValue("purpose", value);
        setPurpose(value);
        // Show migration type dropdown if Purpose is Migration
        
       
        setShowHolidayType(value === "Holidays");
        
        setShowMigrationType(value === "Migration");
        
        
        // Reset migration type if purpose is changed to something else
        if (value !== "Migration") {
            setMigrationType('');
            fresherSetValue("migration_type", '');
        }

        setDisableVisaType(value === "Delegate" || value === "Interview" || value === "Others");

        // Reset holiday type if purpose is changed to something else
        if (value !== "Holidays") {
            setHolidayType('');
            fresherSetValue("holiday_type", '');
        }

            // Reset visa type if disabled
        if (value === "Delegate" || value === "Interview" || value === "Others") {
            setVisaId('');
            fresherSetValue("visa_type_id", '');
        }
    };

    

    const onFresherSubmit = async (data, saveAndCreate) => {
        
        try {
            setIsLoading(true);
            
            const dataValues = {
                first_name: data.fname,
                middle_name: data.mname,
                last_name: data.lname,
                phone: data.phone,
                landline_no: data.landline_no,
                visa_type_id: visa,
                email: data.email,
                purpose: data.purpose,
                reference: data.reference,
                office_id: office,
                assign_to_counselor: data.assign,
                created_by: store?.user?.id,
                walkin_status: "FRESHER",
                saveAndCreate: saveAndCreate,
                migration_type: data.purpose === "Migration" ? data.migration_type : null,
                holiday_type: data.purpose === "Holidays" ? data.holiday_type : null,
            };

            const response = await AddWalkin.mutateAsync(dataValues);
            
            if (response?.message === "Success" && data.comments) {
                const followupData = {
                    applicant_id: response?.appData?.id,
                    assign: store?.user?.id,
                    comments: data.comments,
                    type: "GENERAL",
                    followupdate: null,
                    date_enable: "NO",
                    status: "ACTIVE"
                };
                
                await addFollowupMutation.mutateAsync(followupData);
                setIsLoading(false);
                toast.success('Walk-in saved and assigned successfully');
                navigate('/walkinform');
            } else if (response?.message === "Success") {
                setIsLoading(false);
                toast.success('Walk-in saved and assigned successfully');
                navigate('/walkinform');
            }
            
        } catch (error) {
            setIsLoading(false);
            //toast.error(error?.message || 'Something went wrong');
        }
    };





    const getApplicant = authQueries.useWalkinClientSearch(
        (res) => {

            if (res?.message === "success") {
                setResult(res?.data)
            }
        }
    )


    const AllStaffList = authQueries.useWalkinStaffList(
        (response) => {
            setAllStaff(response)
            let dataArray = []
            for (let datas of response) {

                const dataValues = {
                    label: datas?.user?.userdetail?.firstname + ' ' + datas?.user?.userdetail?.lastname,
                    value: datas?.user?.id
                }

                dataArray = [...dataArray, dataValues]
            }

            setAllStaff(dataArray)
        }
    );


    const prospectList = authQueries.useProspectList(
        (response) => {
            setProspects(response)
            let dataArray = []
            for (let datas of response?.data?.items) {

                const dataValues = {
                    label: datas?.name,
                    value: datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }

            setProspects(dataArray)
        }
    );



    const officeList = authQueries.useOfficeMutationList(
        (response) => {
            setOfficeData(response)
            let dataArray = []
            for (let datas of response?.data?.items) {
                const dataValues = {
                    label: datas?.name,
                    value: datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }

            setOfficeData(dataArray)
        }
    );

    const masterList = authQueries.useWalkinMasterList(
        (response) => {
            setUsers(response)
            let dataArray = []
            let visaArray = []

            for (let datas of response?.data?.user) {
                const dataValues = {
                    label: datas?.userdetail?.firstname + ' ' + datas?.userdetail?.lastname,
                    value: datas?.id
                }

                dataArray = [...dataArray, dataValues]
            }
        

            for (let datas of response?.data?.visatype) {

                const dataValues = {
                    label: datas?.name,
                    value: datas?.id
                }

                visaArray = [...visaArray, dataValues]
            }
           
            setVisaData(visaArray)
            setUsers(dataArray)
        }
    );

    const handleOfficeSelect = () => {
        console.log("visa,", visa)
        masterList.mutateAsync({ visa, office, refferal: '' });
    };

    const AddWalkin = authQueries.useWalkincreate(
        (response) => {
            if (response?.message === "Success") {

            } else {
                setIsLoading(false);

                if(response?.message){
                    toast.error(response?.message)
                }
            }
        }
    );

    const AddFollowup = authQueries.useAppointClientAdd(
        (response) => {

        }
    );
    

    const onSearch = (data) => {
        getApplicant.mutateAsync(data)
    }

    const addFollowupMutation = authQueries.useAppointClientAdd(
        (response) => {
        }
    );


    return (
        <div>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/walkinform">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Add Fresh Walk-In</li>
                    </ol>
                </nav>
            </div>
            <div className="add_clients" style={{ borderBottom: '0px' }}>
                <div className="flex items-center gap-[4px] mb-4">
                    <i className="followup-icon">
                        <img src={require('../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                    </i>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <span className="text-[16px] font-medium">Fresh Walk-In</span>
                        </div>
                    </div>
                </div>
                <div id="accordion" className="add_clients_accordion_form">
                    <div className="card" id="appointments_wrpr">
                        <div className="card-header flex justify-between">
                            <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                                Fresh Walk-In
                            </a>
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className='col-md-12'>

                                    <form onSubmit={fresherHandleSubmit(onFresherSubmit)}>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">First Name<em className="mandatory">*</em></label>
                                                        <input className="form-control" {...fresherRegister("fname")} type="text" name='fname' />
                                                        <div className="text-danger">{fresherErrors.fname?.message}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Middle Name</label>
                                                        <input className="form-control" {...fresherRegister("mname")} type="text" name='mname' />
                                                        <div className="text-danger">{fresherErrors.mname?.message}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Last Name</label>
                                                        <input className="form-control" {...fresherRegister("lname")} type="text" name='lname' />
                                                        <div className="text-danger">{fresherErrors.lname?.message}</div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Phone<em className="mandatory">*</em></label>
                                                        <Controller
                                                            name='phone'
                                                            control={fresherControl}
                                                            className="form-control"
                                                            render={({ field: { onChange, value } }) => (
                                                                <div

                                                                onPaste={(e) => {
                                                                    // e.preventDefault();
                                                                    let pastedText = e.clipboardData.getData("text").replace(/\D/g, "");
                                                                    if(!pastedText.startsWith("91")){
                                                                        pastedText = "91" + pastedText; // Ensure it starts with +91
                                                                    }
                                                                    setCode("in");
                                                                    setMobile(pastedText);
                                                                    setMobileValid("");
                                                                    onChange(pastedText);
                                                                }}

                                                                >
                                                                <PhoneInput
                                                                    enableSearch={true}
                                                                    country={code}
                                                                    value={value}
                                                                    onChange={(value, country, e, formattedValue) => {
                                                                        const digitsOnly = formattedValue.replace(/\D/g, ''); // Remove non-digit characters
                                                                        const countryCodeLength = country.countryCode.length; // Get length of country code
                                                                        const extractedPhoneNumber = digitsOnly.slice(countryCodeLength);
                                                                        setCode(country.countryCode)
                                                                        const parsedPhoneNumber = parsePhoneNumberFromString(`+${value}`);
                                                                        if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
                                                                            onChange(value)
                                                                            setMobile(extractedPhoneNumber);
                                                                            setMobileValid('')
                                                                        }
                                                                    }}
                                                                />
                                                                </div>
                                                            )}
                                                            rules={{ required: true }}
                                                        />
                                                        <div className="text-danger">{fresherErrors.phone?.message}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Landline number</label>
                                                        <Controller
                                                            name='landline_no'
                                                            control={fresherControl}
                                                            className="form-control"
                                                            render={({ field: { onChange, value } }) => (
                                                                <div

                                                                onPaste={(e) => {
                                                                    // e.preventDefault();
                                                                    let pastedText = e.clipboardData.getData("text").replace(/\D/g, "");
                                                                    if(!pastedText.startsWith("91")){
                                                                        pastedText = "91" + pastedText; // Ensure it starts with +91
                                                                    }
                                                                    setCodeLandline("in");
                                                                    setMobile(pastedText);
                                                                    setMobileValid("");
                                                                    onChange(pastedText);
                                                                }}
                                                                >
                                                                <PhoneInput
                                                                    enableSearch={true}
                                                                    country={codeLandline}
                                                                    value={value}
                                                                    onChange={(value, country, e, formattedValue) => {
                                                                        const digitsOnly = formattedValue.replace(/\D/g, ''); // Remove non-digit characters
                                                                        const countryCodeLength = country.countryCode.length; // Get length of country code
                                                                        const extractedPhoneNumber = digitsOnly.slice(countryCodeLength);
                                                                        setCodeLandline(country.countryCode)
                                                                        const parsedPhoneNumber = parsePhoneNumberFromString(`+${value}`);
                                                                        if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
                                                                            onChange(value)
                                                                            setMobile(extractedPhoneNumber);
                                                                            setMobileValid('')
                                                                        }
                                                                    }}
                                                                />
                                                                </div>
                                                            )}
                                                            rules={{ required: true }}
                                                        />
                                                        <div className="text-danger">{fresherErrors.landline_no?.message}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Email</label>
                                                        <input className="form-control"  {...fresherRegister("email")} type="email" name='email' />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Purpose</label>
                                                        <select   {...fresherRegister("purpose")} 
                                                            onChange={handlePurposeChange} className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" name='name'>
                                                            <option value="">Select Purpose</option>
                                                            <option value="Study">Study Enquiry</option>
                                                            <option value="Migration">Migration Enquiry</option>
                                                            <option value="Delegate">Delegate Visit</option>
                                                            <option value="Interview">Interview</option>
                                                            <option value="Holidays"> M&G Holidays</option>
                                                            <option value="Others">Others</option>
                                                        </select>
                                                        <div className="text-danger">{fresherErrors.purpose?.message}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            {showMigrationType && (
                                                <div className="col-md-6">
                                                    <div className='form-group'>
                                                        <div id='parent' className='form-group'>
                                                            <label htmlFor="">Migration Type</label>
                                                            <select 
                                                                {...fresherRegister("migration_type")} 
                                                                onChange={(e) => {
                                                                    setMigrationType(e.target.value);
                                                                    fresherSetValue("migration_type", e.target.value);
                                                                }}
                                                                className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0"
                                                            >
                                                                <option value="">Select Migration Type</option>
                                                                <option value="Canada">Canada Migration</option>
                                                                <option value="Australia">Australia Migration</option>
                                                            </select>
                                                            <div className="text-danger">{fresherErrors.migration_type?.message}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {showHolidayType && (
                                                <div className="col-md-6">
                                                    <div className='form-group'>
                                                        <div id='parent' className='form-group'>
                                                            <label htmlFor="">Holiday Type</label>
                                                            <select 
                                                                {...fresherRegister("holiday_type")} 
                                                                onChange={(e) => {
                                                                    setHolidayType(e.target.value);
                                                                    fresherSetValue("holiday_type", e.target.value);
                                                                }}
                                                                className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0"
                                                            >
                                                                <option value="">Select Holidays Type</option>
                                                                <option value="Ticketing">Ticketing</option>
                                                                <option value="Visas">Visas</option>
                                                                <option value="Insurance">Travel Insurance</option>
                                                                <option value="Booking">Group tour booking</option>
                                                            </select>
                                                            <div className="text-danger">{fresherErrors.holiday_type?.message}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}


                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Reference</label>
                                                        <input className="form-control" value={"Walk-in"} {...fresherRegister("reference")} type="text" name='reference' />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Visa Type</label>
                                                        <AutoCompleteChilds
                                                            dis={disableVisaType} 
                                                            control={fresherControl}
                                                            data={visaData}
                                                            name={"visa_type_id"}
                                                            value={visa}
                                                            setVisaId={setVisaId}
                                                            error={fresherErrors.visa_type_id}
                                                        />
                                                        {disableVisaType && (
                                                            <small className="text-muted">
                                                                Visa type not applicable for selected purpose
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Branch</label>
                                                        <AutoCompleteAntdOffice
                                                            setOffice={setOffice}
                                                            control={fresherControl}
                                                            data={officeData}
                                                            name={"office_id"}
                                                            value={store?.user?.office[0]?.oid}
                                                            error={fresherRegister.office_id}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                        <label htmlFor="">Assign to Counselor</label>
                                                        <select className="form-control" name='assign'  {...fresherRegister("assign")}>
                                                            <option value=''>Select Counselor</option>
                                                            {
                                                                users?.map((item, index) => (
                                                                    <option key={index} value={item?.value}>
                                                                        {item?.label}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                        <div className="text-danger">{fresherErrors.assign?.message}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-12'>
                                                <div className='form-group'>
                                                    <div id='parent' className='form-group'>
                                                    <label htmlFor="">Comments {purpose==='Others'?<em className="mandatory">*</em>:''}</label>
                                                        <textarea className="form-control" {...fresherRegister("comments")} type="text" name='comments' />
                                                        <div className="text-danger">{fresherErrors.comments?.message}</div>
                                                    </div>
                                                </div>            
                                            </div>

                                        </div>

                                        <div className="flex justify-end">
                                            {
                                                isLoading ? (
                                                    <button
                                                        type="submit"
                                                        style={{ backgroundColor: '#323a51' }}
                                                        className="px-4 py-2 text-white rounded-md hover:opacity-90"
                                                    >
                                                        Loading...
                                                    </button>
                                                ) : (
                                                    <>
                                                    <button
                                                        type="submit"
                                                        onClick={() => fresherHandleSubmit((data) => onFresherSubmit(data, false))()}
                                                        style={{ backgroundColor: '#323a51' }}
                                                        className="px-4 py-2 text-white rounded-md hover:opacity-90"
                                                    >
                                                        Save
                                                    </button>

                                                    <button
                                                        type="button"
                                                        onClick={() => fresherHandleSubmit((data) => onFresherSubmit(data, true))()}
                                                        style={{ backgroundColor: '#323a51' }}
                                                        className="px-4 py-2 text-white rounded-md hover:opacity-90 ml-2"
                                                    >
                                                        Save and Create
                                                    </button>

                                                    <button
                                                        onClick={() => navigate('/walkinform')}
                                                        type="button"
                                                        style={{ backgroundColor: '#323a51' }}
                                                        className="px-4 py-2 text-white rounded-md hover:opacity-90 ml-2"
                                                    >
                                                        Cancel
                                                    </button>

                                                    </>
                                                    
                                                )
                                            }
                                        </div>

                                    </form>






                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

