import React, { Fragment, useState } from 'react'
import { Controller } from 'react-hook-form';
import parsePhoneNumberFromString from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2';

const MobileUseForm = ({item, control, setError, errors}) => {

    const [countryCode, setCountryCode] = useState("in");

  return (
    <Fragment>
        <div className="text-[12px] h-4 font-[500]">{item?.label} {item?.required &&<span className="text-[red]">*</span>}</div>
        <Controller
        name={item?.name}
        control={control}
        className="form-control"
        render={({ field: { onChange, value } }) => (
            <div
            onPaste={(e) => {
                e.preventDefault();
                let pastedText = e.clipboardData.getData("text").replace(/\D/g, "");

                if (pastedText.startsWith("91")) {
                pastedText = pastedText.slice(2);
                }

                const fullNumber = `+91${pastedText}`;

                setCountryCode("in");
                onChange(fullNumber);
                const parsedPhoneNumber = parsePhoneNumberFromString(fullNumber);
                if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
                    setError('mobile_no', { type: 'manual', message: '' });
                } else {
                    setError('mobile_no', { type: 'manual', message: 'Provide valid Mobile no' });
                }
            }}
            >
            <PhoneInput
                enableSearch={true}
                country={countryCode}
                value={value || ""}
                onChange={(value, country, e, formattedValue) => {
                const digitsOnly = formattedValue.replace(/\D/g, "");
                const countryCodeLength = country.dialCode.length;

                let extractedPhoneNumber = digitsOnly.startsWith(country.dialCode)
                    ? digitsOnly.slice(countryCodeLength)
                    : digitsOnly;

                setCountryCode(country.countryCode);

                const formattedInternational = `+${digitsOnly}`;
                const parsedPhoneNumber = parsePhoneNumberFromString(formattedInternational);

                console.log(parsedPhoneNumber, parsedPhoneNumber?.isValid())

                if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
                    setError('mobile_no', { type: 'manual', message: '' });
                    onChange(formattedInternational);
                } else {
                    setError('mobile_no', { type: 'manual', message: 'Provide valid Mobile no' });
                    onChange(formattedInternational);
                }
                }}
                disableCountryGuessing={true}
            />
            </div>
            )}
        rules={{ required: true }}
        />
        <div className='text-[12px] text-red-600'>{errors?.[item?.name]?.message}</div>
    </Fragment>
  )
}

export default MobileUseForm
