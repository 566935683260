import { Modal } from '@nextui-org/react';
import moment from 'moment';
import React, { Fragment, useState } from 'react'

const FeePaidSplitupModal = ({item}) => {

    const [open, setOpen] = useState(false)

    const handleOpen =()=>{
        setOpen(true)
    }

    const handleClose =()=>{
        setOpen(false)
    }

    const feePaid =()=>{
        const total = item?.feepaids?.reduce((accumulator, item) => {
            return accumulator + parseFloat(item.amount || 0);
        }, 0);
        const roundedTotal = Math.round(total * 100) / 100;
        let formattedNumber = roundedTotal.toFixed(2);
        return formattedNumber
    }

  return (
    <Fragment>
        <button className='bg-gradient-to-r from-blue-500 to-blue-600 text-white inline-flex px-[5px] py-[2px] font-[800] rounded-[6px]' onClick={handleOpen}>
            {feePaid()}
        </button>
        <Modal
        closeButton
        blur
        scroll
        width="800px"
        aria-labelledby="modal-title"
        open={open}
        onClose={handleClose}
        >

            <Modal.Header>
                <h3 className="text-lg font-semibold text-gray-800">Payment Details</h3>
            </Modal.Header>
            <Modal.Body>

                <div className="relative">
                    <div className="relative space-y-6 backdrop-blur-sm">
                        <div className="bg-white/80 backdrop-blur-sm rounded-xl shadow-lg border border-gray-100 overflow-hidden">
                        <div className="bg-gradient-to-r from-blue-50 to-blue-100/50 px-6 py-3 border-b border-gray-200">
                            <h3 className="text-md font-semibold text-gray-800">Applicant Details</h3>
                        </div>
                        
                        <div className="p-6 grid grid-cols-1 md:grid-cols-3 gap-2">
                            <div className="group hover:bg-blue-50/50 p-2 rounded-lg transition-all duration-300">
                                <p className="text-sm text-gray-500 mb-1">Applicant Name</p>
                                <p className="text-sm font-medium text-gray-800">{item?.applicant_name} {item?.middlename} {item?.lastname}</p>
                            </div>

                            <div className="group hover:bg-blue-50/50 p-2 rounded-lg transition-all duration-300">
                                <p className="text-sm text-gray-500 mb-1">Contact Number</p>
                                <p className="text-sm font-medium text-gray-800">{item?.mobile_no || 'N/A'}</p>
                            </div>

                            <div className="group hover:bg-blue-50/50 p-2 rounded-lg transition-all duration-300">
                                <p className="text-sm text-gray-500 mb-1">Visa Type</p>
                                <p className="text-sm font-medium text-gray-800">{item?.visatype?.description || 'N/A'}</p>
                            </div>

                            <div className="group hover:bg-blue-50/50 p-2 rounded-lg transition-all duration-300">
                                <p className="text-sm text-gray-500 mb-1">Intake</p>
                                <p className="text-sm font-medium text-gray-800">{item?.intake?.intake || 'N/A'}</p>
                            </div>

                            <div className="group hover:bg-blue-50/50 p-2 rounded-lg transition-all duration-300">
                                <p className="text-sm text-gray-500 mb-1">Branch</p>
                                <p className="text-sm font-medium text-gray-800">{item?.office?.name || 'N/A'}</p>
                            </div>

                            <div className="group hover:bg-blue-50/50 p-2 rounded-lg transition-all duration-300">
                                <p className="text-sm text-gray-500 mb-1">Status</p>
                                <p className="text-sm font-medium text-gray-800">{item?.status_of_prospect?.name || 'N/A'}</p>
                            </div>
                        </div>
                        </div>

                        <div className="w-full bg-white/80 backdrop-blur-sm rounded-xl shadow-lg border border-gray-100 overflow-hidden">
                            <div className="grid grid-cols-6 bg-gradient-to-r from-gray-50 to-gray-100 border-b border-gray-200">
                                <div className="col-span-1 p-3 text-sm font-semibold text-gray-700">Payment Date</div>
                                <div className="col-span-2 p-3 text-sm font-semibold text-gray-700">Fee Type</div>
                                <div className="col-span-2 p-3 text-sm font-semibold text-gray-700">Payment Through</div>
                                <div className="col-span-1 p-3 text-sm font-semibold text-gray-700 text-right">Amount</div>
                            </div>

                            <div className="divide-y divide-gray-100">
                                {item?.feepaids?.map((payment, index) => (
                                <div 
                                    key={index}
                                    className="grid grid-cols-6 hover:bg-blue-50/50 transition-all duration-200 ease-in-out group"
                                >
                                    <div className="col-span-1 p-3 text-sm text-gray-600">
                                    {moment(payment?.createdAt).format('DD-MM-YYYY')}
                                    </div>
                                    <div className="col-span-2 p-3 text-sm text-gray-600 group-hover:text-gray-900">
                                    {payment?.admission_feetype?.feeType?.name}
                                    </div>
                                    <div className="col-span-2 p-3 text-sm">
                                    <span className="px-3 py-1.5 rounded-full text-xs font-medium bg-gradient-to-r from-blue-500 to-blue-600 text-white shadow-sm">
                                        {payment?.method}
                                    </span>
                                    </div>
                                    <div className="col-span-1 p-3 text-sm font-medium text-gray-700 text-right group-hover:text-blue-600">
                                    {parseFloat(payment?.amount).toFixed(2)}
                                    </div>
                                </div>
                                ))}
                            </div>

                            <div className="grid grid-cols-6 border-t-2 border-gray-200 bg-gradient-to-r from-gray-50 to-gray-100">
                                <div className="col-span-1 p-3 text-sm font-bold text-gray-800">Total</div>
                                <div className="col-span-4" />
                                <div className="col-span-1 p-3 text-sm font-bold text-blue-600 text-right">{feePaid()}</div>
                            </div>
                        </div>
                    </div>
                </div>


            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    </Fragment>
  )
}

export default FeePaidSplitupModal
