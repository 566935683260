import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import authQueries from '../../queries/authQueries';
import LoaderCommon from "../Common/LoaderCommon";
import toast from "react-hot-toast";

const LeadStatus = () => {
    const [countries, setCountries] = useState([]);
    const [refferalFields, setRefferalFields] = useState([]);
    const [entryDate, setEntryDate] = useState(new Date().toISOString().split('T')[0]);
    const [editingCell, setEditingCell] = useState(null);
    const [countryData, setCountryData] = useState({});
    const [leadFormData, setLeadFormData] = useState([]);
    const [newEntryValues, setNewEntryValues] = useState({});
    const [editingNewEntry, setEditingNewEntry] = useState(null);

    const LeadstatusList = authQueries.useLeadstatusList(
        (res) => {
            setCountries(res?.data);
            setRefferalFields(res?.referralFields);
            // Initialize countryData with current values
            const initialData = {};
            res?.data?.forEach(country => {
                initialData[country.countryid] = {};
                res?.referralFields?.forEach(field => {
                    initialData[country.countryid][field] = country[field];
                });
            });
            setCountryData(initialData);
        }
    );

    const LeadFormData = authQueries.useLeadFormData(
        (res) => {
            setLeadFormData(res?.data);
            console.log("res", res?.leads[0]);
            
            // Initialize newEntryValues with saved values if available
            if (res?.newEntryData) {
                setNewEntryValues(res.newEntryData);
            } else {
                // Initialize with zeros if no saved data
                const initialNewValues = {};
                res?.leads?.forEach(field => {
                    initialNewValues[field] = 0;
                });
                setNewEntryValues(res?.leads[0]);
            }
        }
    );

    const updateLeadStatus = authQueries.useLeadStatuscreate(
        (res) => {
            if (res.success) {
                toast.success("Data updated successfully");
            }
        }
    );

    // Assuming you have a mutation for updating new entry values
    const updateNewEntry = authQueries.useLeadStatuscreate(
        (res) => {
            if (res.success) {
                toast.success("Lead updated successfully");
            }
        }
    );

    const onDateChange = (e) => {   
        setEntryDate(e.target.value);
        LeadFormData.mutateAsync({entry_date: e.target.value});
        LeadstatusList.mutateAsync({entry_date: e.target.value});
    }

    const handleCellClick = (countryId, field) => {
        console.log("countryId",countryId, "field",field)
        setEditingCell({ countryId, field });
    }

    const handleCellChange = (e, countryId, field) => {
        const value = parseInt(e.target.value);
        setCountryData(prev => ({
            ...prev,
            [countryId]: {
                ...prev[countryId],
                [field]: value
            }
        }));
    }

    const handleBlur = () => {
        if (editingCell) {
            const payload = {
                userId: 7, // Replace with actual user ID
                entry_date: entryDate,
                leads: countries.map(country => ({
                    country: country.countryid,
                    ...countryData[country.countryid]
                }))
            };
            console.log(payload)
            updateLeadStatus.mutateAsync(payload);
            setEditingCell(null);
        }
    }

    // Handle click on new entry cell
    const handleNewEntryCellClick = (field) => {
        setEditingNewEntry(field);
    }

    // Handle change for new entry inputs
    const handleNewEntryChange = (e, field) => {
        const value = parseInt(e.target.value) || 0;
        setNewEntryValues(prev => {
            return {
                ...prev,
                [field]: value
            };
        });
        console.log("newEntryValues", newEntryValues);
    }

    // Handle blur event for new entry cell
    const handleNewEntryBlur = () => {
        if (editingNewEntry) {
            // Create payload for updating the new entry

            const { createdAt, ...cleanedEntryValues } = newEntryValues;

            const payload = {
                userId: 7, // Replace with actual user ID
                entry_date: entryDate,
                newEntryData: cleanedEntryValues
            };
            
            console.log("Saving new entry:", payload);
            updateNewEntry.mutateAsync(payload);
            setEditingNewEntry(null);
        }
    }

    useEffect(() => {
        LeadstatusList.mutateAsync({entry_date: entryDate});
        LeadFormData.mutateAsync({entry_date: entryDate});
    }, []);

    const reverseReferralField = (formattedRef) => {
        if (!formattedRef.startsWith('ref_')) return null; // Ensure prefix exists
    
        return formattedRef
            .slice(4) // Remove "ref_" prefix
            .replace(/_/g, ' ') // Replace underscores with spaces
            .split(' ') // Split words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
            .join(' '); // Join words back
    };

    // Calculate totals for each referral field
    const calculateTotals = () => {
        const totals = {};
        
        // Initialize totals for each referral field
        refferalFields.forEach(field => {
            totals[field] = 0;
        });
        
        // Sum up values for each field
        leadFormData.forEach(country => {
            refferalFields.forEach(field => {
                if (country[field] !== undefined) {
                    totals[field] += parseInt(country[field] || 0);
                }
            });
        });
        
        return totals;
    };

    const referralTotals = calculateTotals();
    
    // Calculate conversion ratio
    const calculateConversionRatios = () => {
        const ratios = {};
        
        refferalFields.forEach(field => {
            // Calculate ratio: (total leads processed / total leads came) * 100
            const totalProcessed = referralTotals[field] || 0;
            const totalCame = newEntryValues?.[field] || 0;
            
            // Avoid division by zero
            if (totalCame > 0) {
                ratios[field] = ((totalProcessed/totalCame) * 100).toFixed(2);
            } else {
                ratios[field] = '0';
            }
        });
        
        return ratios;
    };

    const conversionRatios = calculateConversionRatios();

    return (
        <div>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                        <li className="breadcrumb-item active">Lead Status</li>
                    </ol>
                </nav>
            </div>
            <div className="add_clients">
                <div className="row">
                    <div className="col-md-6 appointments-hing-wrpr">
                        <i className="appointments-icon">
                            <img src={require("../../assets/images/appointments-today-icon.png")} alt="" />
                        </i>
                        <h2><span>&nbsp;</span>Lead Status</h2>
                    </div>
                    <div className="col-md-12">
                        <hr className="progress-hr" />
                    </div>
                </div>
                <div id="accordion" className="add_clients_accordion_form">
                    <div className="card">
                        <div className="card-header text-[14px]">Lead Status</div>
                        {LeadstatusList.isLoading ? (
                            <LoaderCommon />
                        ) : (
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <label htmlFor="id" className="form-label">Select Date</label>
                                        <input 
                                            type="date" 
                                            onChange={onDateChange} 
                                            value={entryDate} 
                                            className="form-control form-control-sm !min-h-[38px]" 
                                        />
                                        <div className="table-list-search-wrpr table-report-ceo overflow-auto mt-4">
                                            <div className="overflow-x-auto max-h-[800px]">
                                                <table className="table report associate-report collageprogram">
                                                <thead>
                                                    <tr>
                                                        <th className="min-w-[50px] text-center">#</th>
                                                        <th className="min-w-[100px] !text-[13px] text-center">Country</th>
                                                        {refferalFields?.map((field, index) => (
                                                            <th key={index} className="min-w-[50px] !text-[13px] text-center py-2">
                                                                {reverseReferralField(field)}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {leadFormData?.map((country, index) => (
                                                        <tr key={country.countryid}>
                                                            <td className="!text-[13px] text-center">{index + 1}</td>
                                                            <td className="!text-[13px] text-center py-2">{country?.country}</td>
                                                            {refferalFields?.map((field) => (
                                                                <td 
                                                                    key={`${country.countryid}-${field}`}
                                                                    className="!text-[13px] text-center"
                                                                >
                                             
                                                                        <div 
                                                                            onClick={() => handleCellClick(country.countryid, field)}
                                                                            className="cursor-pointer hover:bg-gray-100 p-1"
                                                                        >
                                                                            {country[field] || 0}
                                                                        </div>
                                                                  
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                    <tr className="font-bold bg-gray-100">
                                                        <td className="!text-[16px] text-center" colSpan={2}> 
                                                            <b>Total</b> 
                                                        </td>
                                                        {refferalFields?.map((field) => (
                                                            <td 
                                                                key={`total-${field}`}
                                                                className="!text-[14px] text-center font-bold"
                                                            >
                                                                {referralTotals[field] || 0}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                    <tr className="bg-blue-50">
                                                        <td className="!text-[16px] text-center" colSpan={2}> 
                                                            <b>Total Leads Came</b> 
                                                        </td>
                                                        {refferalFields?.map((field) => (
                                                            <td 
                                                                key={`new-entry-${field}`}
                                                                className="!text-[14px] text-center"
                                                            >
                                                                {editingNewEntry === field ? (
                                                                    <input
                                                                        type="number"
                                                                        className="form-control form-control-sm text-center"
                                                                        value={newEntryValues?newEntryValues[field] : ''}
                                                                        onChange={(e) => handleNewEntryChange(e, field)}
                                                                        onBlur={handleNewEntryBlur}
                                                                        autoFocus
                                                                    />
                                                                ) : (
                                                                    <div 
                                                                        onClick={() => handleNewEntryCellClick(field)}
                                                                        className="cursor-pointer hover:bg-gray-100 p-1"
                                                                    >
                                                                        {newEntryValues?newEntryValues[field]: 0}
                                                                    </div>
                                                                )}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                    <tr className="bg-blue-50">
                                                        <td className="!text-[16px] text-center" colSpan={2}> 
                                                            <b>Conversion Ratio</b> 
                                                        </td>
                                                        {refferalFields?.map((field) => (
                                                            <td 
                                                                key={`ratio-${field}`}
                                                                className="!text-[14px] text-center"
                                                            >
                                                                {conversionRatios[field]}%
                                                            </td>
                                                        ))}
                                                    </tr>
                                                </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LeadStatus;
