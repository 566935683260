import { useState, useEffect, Fragment } from "react";
import { Modal, Progress } from "@nextui-org/react";
import { socket } from "./socket";
import moment from "moment";
import { IconX } from "@tabler/icons-react";
import { userStore } from "../store/userStore";

export default function SocketCallCenter() {

  const user = userStore((state) => state.user);

  const totalDuration = moment().diff(moment().startOf('day').add(9, 'hours'), 'seconds')

  const [isOpen, setOpen] = useState(false);
  const [response, setResponse] = useState({});

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(()=>{
    socket.on('test_modal', (message) => {
        console.log(message)
        if(message?.success){
          const findUser = message?.data?.find((usr) => usr?.user_id === user?.id);
          if(findUser){
            setResponse(findUser)
            handleOpen()
          }
        }
    });
  },[])

  const durationConverter = (duration) => {
    const seconds = duration % 60;
    const minutes = Math.floor(duration / 60) % 60;
    const hours = Math.floor(duration / 3600);

    let result = [];

    if (hours > 0) result.push(`${hours} hours`);
    if (minutes > 0) result.push(`${minutes} minutes`);
    if (seconds > 0 || result.length === 0) result.push(`${seconds} seconds`);

    return result.join(' ');
  };



  return (
    <Fragment>
      <Modal width="500px" open={isOpen} onClose={handleClose}>
          <Modal.Header>
            <div className="w-full flex justify-between items-center">
              <div className="text-[16px] font-bold">
                {moment().format('h:mm A')} call duration alert
              </div>
              <button onClick={handleClose} >
                <IconX size={20} />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="mt-1">
              <div className="text-[14px] font-medium">You have spent {durationConverter(response?.duration)} on calls with clients till now.</div>
              <div className="text-[14px] font-medium text-right my-2">
                {durationConverter(response?.duration)}
              </div>
              <Progress value={response?.duration} max={totalDuration} />
            </div>
          </Modal.Body>
          <Modal.Footer />
      </Modal>
    </Fragment>
  );
}
