import React, { Fragment, useEffect, useRef, useState } from "react";
import authQueries from '../../../queries/authQueries';
import { Link, useNavigate } from "react-router-dom";
import DropDown from "../../Common/DropDown";
import moment from "moment";
import { Checkbox, Loading } from "@nextui-org/react";
import { Empty } from "antd";
import { useLoaderStore, usePaginationStore } from "../../../store/userStore";
import { IconChevronLeft, IconChevronRight, IconMoneybag } from "@tabler/icons-react";
import InputSearch from "../../Common/InputSearch";
import SelectSearch from "../../Common/SelectSearch";
import PaginationComponent from "../../Common/PaginationComponent";
import LoaderCommon from "../../Common/LoaderCommon";
import LeadStatus from "../../Common/LeadStatus";
import AdvancedSearch from "../../Common/AdvancedSearch";
import { useAdvancedSearch } from "../../../store/advancedSearchStore";
import FeePaidSplitupModal from "../../Common/FeePaidSplitupModal";
import { userStore } from '../../../store/userStore'
import { IconClearAll } from '@tabler/icons-react';
import ApplicationStatusCreateModal from "../../Common/ApplicationStatusCreateModal";
import ApplicationStatusModal from "../../Common/ApplicationStatusModal";
import { decode } from "html-entities";

const FeePaidReport =()=>{

    const [result, setResult] = useState([]);

    const data = useAdvancedSearch((state) => state);
    const setLoader = useLoaderStore((state) => state.setLoader);
    const resetPage = usePaginationStore((state) => state.resetPage);
    const resetAll = useAdvancedSearch((state)=> state.resetAll);
    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);
    const [active, setActive] = useState(null);
    
    const feePaidReport = authQueries.useCEOFeePaidMutation(
        (response) => {
            setResult(response);   
            setLoader(false)     
        }
    );

    const offerAccepted = authQueries.useOfferAcceptedProgramMutation(
        (res)=>{
        }
    )

    useEffect(()=>{
        feePaidReport.mutateAsync({
            page: currentPage,
            size: 100,
            ...data
        });
    },[data, currentPage]);

    const counselorName =(counselor)=>{
        if(!counselor){
            return 'N/A'
        }
        return `${counselor?.firstname} ${counselor?.lastname}`
    }

    const feePaid =()=>{
        let newCount = 0
        result?.data?.items?.map((item)=>{            
            const total = item?.feepaids?.reduce((accumulator, item) => {
                return accumulator + parseFloat(item.amount || 0);
            }, 0);
            const roundedTotal = Math.round(total * 100) / 100;
            let formattedNumber = roundedTotal.toFixed(2);
            newCount += parseFloat(formattedNumber)
        })
        return newCount.toFixed(2)
    }

    const resetFilters =()=>{
        resetAll()
        resetPage()
    }

    const handleClick =(id)=>{
        if(active === id){
            setActive(null)
            return
        }
        setActive(id)
        offerAccepted.mutateAsync(id)
    }

    return(
        <div>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Fee Paid Report</li>
                </ol>
                </nav>
            </div>
            
            <div className="add_clients">
                <div className="flex items-center gap-[4px] mb-4">
                    <i className="followup-icon">
                        <IconMoneybag size="25" className='text-[#7D6FAB]' />
                    </i>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                            <span className="text-[16px] font-medium">Fee Paid Report</span>	
                        </div>
                    </div>
                </div>
                <div id="accordion" className="add_clients_accordion_form mt-[10px]">
                    <div  className="card" id="appointments_wrpr_employee">
                    <div className="card-header flex justify-between">
                            <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                                Fee Paid Report
                            </a>
                            <div>
                                <button className='text-[12px] flex gap-2 items-center' onClick={resetFilters}>
                                    <IconClearAll size={17} />
                                    Clear Filters
                                </button>
                            </div>
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">

                                <AdvancedSearch report={true} feepayvendor={true}  />

                                <div className="search-results-count-wrpr flex justify-between items-center mt-4">
                                    {
                                    result?.data?.totalItems > 0 ?
                                    `Search Results ${result?.data?.currentPage * 100 + 1}-${result?.data?.currentPage * 100 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                    `O results found`
                                    }
                                </div>

                                {feePaidReport.isLoading ?
                                    <LoaderCommon />:
                                    result?.data?.totalItems > 0 ?
                                    <div className="table-wrpr">
                                        <div className="table-section">
                                            <div className="row">   
                                                <div className="col-md-12">                                   
                                                    <table border={1} className="table prospect-table">
                                                        <thead>
                                                            <tr className="!font-bold">
                                                                <th width="30">#</th>
                                                                <th>Profile Status</th>
                                                                <th>Office</th>
                                                                <th>Applicant Name</th>
                                                                <th>Contact Number</th>
                                                                <th>Visa Type</th>
                                                                <th>Intake</th>
                                                                <th>Counselor</th>
                                                                <th>Reg. Date</th>
                                                                <th>Payment Date</th>
                                                                <th>Amount</th>
                                                                <th>Payment Through</th>
                                                                <th>Prospect Status</th>                                                               
                                                            </tr>
                                                        </thead>
                                                        <tbody  className="ui-sortable">
                                                            {   
                                                                result?.data?.items?.map((item, index) =>{
                                                                    return(
                                                                    <Fragment key={index}>
                                                                        <tr className="ui-sortable-handle">
                                                                            <td>{(index + 1) + (result?.data?.currentPage * 100)}</td>
                                                                            <td><LeadStatus item={item} /></td>
                                                                            <td>{item?.office?.name}</td>
                                                                            <td>
                                                                                <a style={{ cursor: 'pointer', color: '#1000FF' }} onClick={() => { handleClick(item?.id) }}>
                                                                                    {decode(item?.applicant_name)} {decode(item?.middlename)} {decode(item?.lastname)} <i className='icon-arrow-down-thick'></i>
                                                                                </a>
                                                                            </td>
                                                                            <td>
                                                                                <div className="email-mobile flex flex-col">
                                                                                    <div>{item?.mobile_no}</div>
                                                                                    <div>{item?.landline_no}</div>
                                                                                    <div>{item?.whatsapp_no}</div>
                                                                                </div>
                                                                            </td>
                                                                            <td>{item?.visatype?.country?.icon} {item?.visatype?.description} </td>
                                                                            <td>{item?.intake?.intake}</td>
                                                                            <td>{counselorName(item?.assignUser?.CounselorAssign?.userdetail)}</td>
                                                                            <td>{item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}</td>
                                                                            <td className="font-bold">
                                                                                <span className="feedate">
                                                                                    {moment(item?.feepaids?.[0]?.createdAt).format('DD-MM-YYYY')}
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <FeePaidSplitupModal item={item} />
                                                                            </td>
                                                                            <td>{item?.feepaids?.[0]?.method}</td>
                                                                            <td>{item?.status_of_prospect?.name}</td>                                                                        
                                                                        </tr>
                                                                        {active === item?.id && offerAccepted?.data?.data?.length > 0 ?
                                                                            <tr>
                                                                                <td colSpan="13" className={active === item?.id ? "dropdown-table-wrpr active" : "dropdown-table-wrpr"}>
                                                                                    <table className="table dropdown-table">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Prioriy</th>
                                                                                                <th>College / University</th>
                                                                                                <th>Program Name</th>
                                                                                                <th>Intake</th>
                                                                                                <th>Application Status</th>
                                                                                                <th>Duration</th>
                                                                                                <th>Code</th>
                                                                                                <th>Status</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {offerAccepted?.data?.data
                                                                                            ?.map((items, indexes) => (
                                                                                                <tr key={indexes}>
                                                                                                    <td width="15">
                                                                                                        <div
                                                                                                        className='font-[600] flex items-center justify-center w-[25px] h-[25px] rounded-full text-white'
                                                                                                        style={{background: items?.status === "CONFIRMED" ? 'green' : 'orange'}}>
                                                                                                            {items?.priority}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{items?.college?.name}</td>
                                                                                                    <td>
                                                                                                        {items?.program?.programlink ?
                                                                                                        <a className='text-blue-700' href={items?.program?.programlink} target='_blank'>
                                                                                                            {items?.program?.name}
                                                                                                        </a>:
                                                                                                        <div>{items?.program?.name}</div>
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td>{items?.intake?.intake}</td>
                                                                                                    <td>
                                                                                                        <ApplicationStatusModal item={items} />
                                                                                                    </td>
                                                                                                    <td>{items?.program?.duration && `${items?.program?.duration} Year`}</td>
                                                                                                    <td>{items?.program?.program_code}</td>
                                                                                                    <td>{items?.status}</td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>:
                                                                            active === item?.id && offerAccepted?.data?.data?.length === 0 ?
                                                                            <tr>
                                                                                <td colSpan="13">
                                                                                    <div className="text-center">No Offer Accepted</div>
                                                                                </td>
                                                                            </tr>:
                                                                            null
                                                                        }
                                                                    </Fragment>
                                                                )}
                                                            )}
                                                            {
                                                                result?.data?.totalItems > 0 &&
                                                                <tr>
                                                                    <td colSpan={9} />
                                                                    <td className="font-semibold text-[13px]">Total Amount</td>
                                                                    <td className="font-semibold text-[13px]">{feePaid()}</td>
                                                                    <td colSpan={3}></td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>:
                                    <div className='mt-24 mb-24'>
                                        <Empty
                                        description={<div className="text-sm text-blue-700" style={{fontFamily: "var(--primary-font)"}}>Clients not found!</div>}
                                        />
                                    </div>
                                }
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    
}
export default FeePaidReport;